/* GENERATED! Do not touch.*/

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
import {
  graphql,
  type GraphQLResponseResolver,
  type RequestHandlerOptions,
} from 'msw'
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: any
  JSONObject: any
  PgDate: any
}

export type AddressInfoInput = {
  readonly address1: Scalars['String']
  readonly address2?: InputMaybe<Scalars['String']>
  readonly city?: InputMaybe<Scalars['String']>
  readonly country: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName: Scalars['String']
  readonly postalCode?: InputMaybe<Scalars['String']>
  readonly province?: InputMaybe<Scalars['String']>
  readonly state?: InputMaybe<Scalars['String']>
  readonly suburb?: InputMaybe<Scalars['String']>
  readonly zip?: InputMaybe<Scalars['String']>
}

export type AvailableToAcceptBountyType = {
  readonly __typename: 'AvailableToAcceptBountyType'
  readonly isValid: Scalars['Boolean']
  readonly validationCode: AvailableToAcceptBounty_ValidationCode
}

export type AvailableToAcceptBounty_ValidationCode =
  | 'ACCEPT_WINDOW_EXPIRED'
  | 'C_LEVEL_ACCEPT_LIMIT_REACHED'
  | 'BOUNTY_NOT_PENDING_STATE'
  | 'CREATOR_BLOCKED_BY_STORE'
  | 'CREATOR_OVER_STORE_POST_LIMIT'
  | 'NO_USER_ASSOCIATED_TO_ORDER'
  | 'ORDER_HAS_NO_ENABLED_PRODUCTS'
  | 'ORDER_NOT_DELIVERED'
  | 'ORDER_NOT_FOUND'
  | 'PRODUCT_DISABLED'
  | 'RETAIL_BOUNTY_IN_PROGRESS'
  | 'SERVER_ERROR'
  | 'STORE_OVER_MONTHLY_SPEND_BUDGET'
  | 'STORE_SNIPPETS_DISABLED'
  | 'STORE_SUBSCRIPTION_INACTIVE'
  | 'STORE_TIKTOK_USERNAME_NOT_SET'
  | 'UNKNOWN_STORE_FOR_BOUNTY'
  | 'VALID'

export type BountyBonuses =
  | 'AUDIO'
  | 'DEMONSTRATION'
  | 'HOOK'
  | 'LIGHTING'
  | 'LOGO'
  | 'PRESENCE'
  | 'PRODUCT_IN_FIRST_5_SECONDS'
  | 'UNIQUE'

export type BountyModifier = {
  readonly bonuses: ReadonlyArray<BountyBonuses>
  readonly penalties: ReadonlyArray<BountyPenalties>
}

export type BountyPenalties =
  | 'COPYRIGHTED_CONTENT'
  | 'PRODUCT_NOT_VISIBLE'
  | 'PROFANITY'

export type Brief = {
  readonly __typename: 'Brief'
  readonly content: Scalars['String']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly isDefault: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly paymentSnapshot?: Maybe<Scalars['JSON']>
  readonly slug: Scalars['String']
  readonly videoUrl?: Maybe<Scalars['String']>
}

export type ChatMessageMeta = {
  readonly id: Scalars['String']
}

export type ChatMessageMetaGiftOffer = ChatMessageMeta & {
  readonly __typename: 'ChatMessageMetaGiftOffer'
  readonly createdAt: Scalars['PgDate']
  readonly giftType: GiftOfferType
  readonly giftValue?: Maybe<Scalars['Float']>
  readonly id: Scalars['String']
  readonly offerStatus: GiftOfferStatus
}

export type ChatMessageMetaResult =
  | ChatMessageMetaGiftOffer
  | ChatMessageMetaSparkCode

export type ChatMessageMetaSparkCode = ChatMessageMeta & {
  readonly __typename: 'ChatMessageMetaSparkCode'
  readonly bountyId: Scalars['String']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly sparkCodeAmount: Scalars['Float']
  readonly sparkCodeStatus: SparkAdCodeRequestStatus
}

export type CheckForNewVideoResult = {
  readonly __typename: 'CheckForNewVideoResult'
  readonly code: CheckForNewVideoResultCode
  /** We may have more context, maybe not */
  readonly message?: Maybe<Scalars['String']>
}

export type CheckForNewVideoResultCode =
  | 'ERROR'
  | 'RATE_LIMIT'
  | 'VIDEO_FOUND'
  | 'VIDEO_NOT_FOUND'

export type Conversation = {
  readonly __typename: 'Conversation'
  readonly avatarUrl?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly lastReceivedActionableMessage?: Maybe<Message>
  readonly latestMessage?: Maybe<Message>
  /** Don't call this on the list query, it will be slow */
  readonly messages?: Maybe<ReadonlyArray<Message>>
  readonly name: Scalars['String']
  readonly unreadCount?: Maybe<Scalars['Int']>
}

export type ConversationMessagesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type ConversationInput = {
  readonly skip?: InputMaybe<Scalars['Int']>
  readonly take?: InputMaybe<Scalars['Int']>
}

export type ConversationListResponse = {
  readonly __typename: 'ConversationListResponse'
  readonly count: Scalars['Int']
  readonly items: ReadonlyArray<Conversation>
  readonly skip: Scalars['Int']
  readonly take: Scalars['Int']
}

export type CreateRetailBountyError = {
  readonly __typename: 'CreateRetailBountyError'
  readonly bountyId?: Maybe<Scalars['String']>
  readonly code: CreateRetailBountyErrorCode
  readonly message?: Maybe<Scalars['String']>
}

export type CreateRetailBountyErrorCode =
  | 'PRODUCT_NOT_FOUND'
  | 'RETAIL_BOUNTY_IN_PROGRESS'

export type CreateRetailBountyInput = {
  readonly productId?: InputMaybe<Scalars['String']>
  readonly storeId: Scalars['String']
}

export type CreateRetailBountyResult = CreateRetailBountyError | UserBounty

export type CreateUserProfileInfo = {
  readonly address1: Scalars['String']
  readonly address2?: InputMaybe<Scalars['String']>
  readonly city?: InputMaybe<Scalars['String']>
  readonly country: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName: Scalars['String']
  readonly postalCode?: InputMaybe<Scalars['String']>
  readonly province?: InputMaybe<Scalars['String']>
  readonly state?: InputMaybe<Scalars['String']>
  readonly suburb?: InputMaybe<Scalars['String']>
  readonly zip?: InputMaybe<Scalars['String']>
}

export type CreatorNotificationType =
  | 'AD_HOC_120_HOURS'
  | 'AD_HOC_BOUNTY_GRANTED'
  | 'AD_HOC_EXPIRED'
  | 'AD_HOC_HOW_IT_WORKS'
  | 'AD_HOC_LAST_DAY'
  | 'BONUS_RETENTION_OFFER'
  | 'BONUS_RETENTION_OFFER_REMINDER_1'
  | 'BONUS_RETENTION_OFFER_REMINDER_2'
  | 'BOUNTY_APPROVED'
  | 'BOUNTY_DENIED'
  | 'BOUNTY_LIVE_INSTAGRAM'
  | 'BOUNTY_LIVE_TIKTOK'
  | 'BRAND_TESTING_CONTENT'
  | 'CUSTOM_MESSAGE_SHOP_PAGE_LINK'
  | 'MANUAL_BONUS_PAYMENT_ISSUED'
  | 'NEW_BOUNTY_ADDED'
  | 'ON_ACCEPT_24HR_NO_VIDEO'
  | 'ORDER_DELIVERED_9DAYS_FEEDBACK'
  | 'ORDER_DELIVERED_10_DAYS'
  | 'ORDER_DELIVERED_14_DAYS'
  | 'ORDER_DELIVERED_24HR'
  | 'ORDER_DELIVERED_24HR_EXPIRED'
  | 'ORDER_DELIVERED_24HR_LEFT'
  | 'ORDER_DELIVERED_48HR_LEFT'
  | 'ORDER_DELIVERY_TIMEOUT_7DAYS'
  | 'ORDER_DELIVERY_TIMEOUT_14DAYS'
  | 'ORDER_DELIVERY_TIMEOUT_FIRST'
  | 'PAYMENT_READY_TO_WITHDRAW'
  | 'RETAIL_EXPIRED_BOUNTY_24HRS'
  | 'RETAIL_HOW_IT_WORKS'
  | 'RETAIL_NOT_POSTED_72HRS'
  | 'RETAIL_NOT_POSTED_120HRS'
  | 'RETAIL_NOT_POSTED_LAST_DAY'
  | 'SEND_FREE_PRODUCT_OR_BOUNTY_TO_CREATOR'
  | 'SEND_FREE_PRODUCT_TO_NETWORK_CREATOR'
  | 'SEND_TIP_RECEIVED'
  | 'SPARK_CODE_REQUEST'
  | 'SPARK_CODE_REQUEST_FOLLOW_UP'
  | 'SPARK_CODE_REQUEST_PAYMENT_ISSUED'
  | 'USER_GIFT_FOLLOWUP_1'
  | 'USER_GIFT_FOLLOWUP_2'
  | 'VALID_ORDER_PLACED'
  | 'WITHDRAW_COMPLETED'
  | 'WITHDRAW_INITIATED'

export type DeleteUserResponse = {
  readonly __typename: 'DeleteUserResponse'
  readonly code: Scalars['String']
  readonly message: Scalars['String']
  readonly success: Scalars['Boolean']
}

export type EmailVerificationRequestResponse = {
  readonly __typename: 'EmailVerificationRequestResponse'
  readonly code: Scalars['String']
  readonly message: Scalars['String']
  readonly success: Scalars['Boolean']
}

export type GiftOffer = {
  readonly createdAt: Scalars['PgDate']
  readonly expiresAt?: Maybe<Scalars['PgDate']>
  readonly id: Scalars['String']
  readonly key: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly refType?: Maybe<GiftOfferRefType>
  readonly shopUrl: Scalars['String']
  readonly status: GiftOfferStatus
  readonly storeId: Scalars['String']
  readonly storeLogo?: Maybe<Scalars['String']>
  readonly storeName?: Maybe<Scalars['String']>
  readonly type: GiftOfferType
  readonly updatedAt: Scalars['PgDate']
}

export type GiftOfferBounty = GiftOffer & {
  readonly __typename: 'GiftOfferBounty'
  readonly createdAt: Scalars['PgDate']
  readonly expiresAt?: Maybe<Scalars['PgDate']>
  readonly id: Scalars['String']
  readonly key: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly minBountyPayment: Scalars['Float']
  readonly offerValue?: Maybe<Scalars['Float']>
  readonly refType?: Maybe<GiftOfferRefType>
  readonly shopUrl: Scalars['String']
  readonly status: GiftOfferStatus
  readonly storeId: Scalars['String']
  readonly storeLogo?: Maybe<Scalars['String']>
  readonly storeName?: Maybe<Scalars['String']>
  readonly type: GiftOfferType
  readonly updatedAt: Scalars['PgDate']
}

export type GiftOfferBountyDetails = {
  readonly __typename: 'GiftOfferBountyDetails'
  readonly id: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly minBountyPayment: Scalars['Float']
  readonly storeName: Scalars['String']
}

export type GiftOfferDetailsResult =
  | GiftOfferBountyDetails
  | GiftOfferError
  | GiftOfferProductDetails

export type GiftOfferError = {
  readonly __typename: 'GiftOfferError'
  readonly code: GiftOfferErrorCode
  readonly message?: Maybe<Scalars['String']>
}

export type GiftOfferErrorCode =
  | 'EXTERNAL_INVITE_NOT_FOUND'
  | 'OFFER_CLAIMED'
  | 'OFFER_COMPLETED'
  | 'OFFER_DISABLED'
  | 'OFFER_EXPIRED'
  | 'OFFER_INVALID'
  | 'OFFER_NOT_FOR_USER'
  | 'OFFER_NOT_FOUND'
  | 'OFFER_OPEN'
  | 'OFFER_REJECTED'
  | 'PROSPECT_NOT_FOUND'
  | 'USER_NOT_FOUND'

export type GiftOfferProduct = GiftOffer & {
  readonly __typename: 'GiftOfferProduct'
  readonly createdAt: Scalars['PgDate']
  readonly expiresAt?: Maybe<Scalars['PgDate']>
  readonly id: Scalars['String']
  readonly key: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly offerValue?: Maybe<Scalars['Float']>
  readonly paymentSnapshot?: Maybe<UserBounty_PaymentSnapshot>
  readonly products: ReadonlyArray<GiftOfferProductOption>
  readonly refType?: Maybe<GiftOfferRefType>
  readonly shopUrl: Scalars['String']
  readonly shopifyStoreOrderId?: Maybe<Scalars['String']>
  readonly status: GiftOfferStatus
  readonly storeId: Scalars['String']
  readonly storeLogo?: Maybe<Scalars['String']>
  readonly storeName?: Maybe<Scalars['String']>
  readonly type: GiftOfferType
  readonly updatedAt: Scalars['PgDate']
}

export type GiftOfferProductDetailOption = {
  readonly __typename: 'GiftOfferProductDetailOption'
  readonly id: Scalars['String']
  readonly imageUrl?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly price?: Maybe<Scalars['Float']>
  readonly productLink?: Maybe<Scalars['String']>
  readonly quantity: Scalars['Int']
  readonly storeName: Scalars['String']
  readonly variants: ReadonlyArray<GiftOfferProductVariant>
}

export type GiftOfferProductDetails = {
  readonly __typename: 'GiftOfferProductDetails'
  readonly email?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly products: ReadonlyArray<GiftOfferProductDetailOption>
}

export type GiftOfferProductOption = {
  readonly __typename: 'GiftOfferProductOption'
  readonly id: Scalars['String']
  readonly imageUrl?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly price?: Maybe<Scalars['Float']>
  readonly productLink: Scalars['String']
  readonly quantity: Scalars['Int']
}

export type GiftOfferProductVariant = {
  readonly __typename: 'GiftOfferProductVariant'
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type GiftOfferProductVariantInput = {
  readonly productId: Scalars['String']
  readonly variantId: Scalars['String']
}

export type GiftOfferRefType = 'EXTERNAL_INVITE' | 'PROSPECT' | 'USER'

export type GiftOfferResult =
  | GiftOfferBounty
  | GiftOfferError
  | GiftOfferProduct

export type GiftOfferStatus = 'CLAIMED' | 'COMPLETED' | 'OPEN' | 'REJECTED'

export type GiftOfferType = 'BOUNTY_AD_HOC' | 'DISCOUNT_CODE' | 'PRODUCT'

export type InstagramContent = {
  readonly __typename: 'InstagramContent'
  readonly commentCount: Scalars['Int']
  readonly contentId: Scalars['String']
  readonly createdAt: Scalars['PgDate']
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly imageUrl: Scalars['String']
  readonly instagramContentPolls: ReadonlyArray<InstagramContentPoll>
  readonly likeCount: Scalars['Int']
  readonly shareCount: Scalars['Int']
  readonly title: Scalars['String']
  readonly type: InstagramContentType
  readonly updatedAt: Scalars['PgDate']
  readonly url: Scalars['String']
  readonly userBountyId: Scalars['String']
  readonly viewCount: Scalars['Int']
}

export type InstagramContentPoll = {
  readonly __typename: 'InstagramContentPoll'
  readonly commentCount: Scalars['Int']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly instagramContentId: Scalars['String']
  readonly likeCount: Scalars['Int']
  readonly shareCount: Scalars['Int']
  readonly viewCount: Scalars['Int']
}

export type InstagramContentType = 'POST' | 'REEL' | 'STORY'

export type InstagramProfile = {
  readonly __typename: 'InstagramProfile'
  readonly bioDescription?: Maybe<Scalars['String']>
  readonly createdAt: Scalars['PgDate']
  readonly displayName?: Maybe<Scalars['String']>
  readonly followerCount?: Maybe<Scalars['Int']>
  readonly followingCount?: Maybe<Scalars['Int']>
  readonly id: Scalars['String']
  readonly likesCount?: Maybe<Scalars['Int']>
  readonly profileLink?: Maybe<Scalars['String']>
  readonly profileName: Scalars['String']
  readonly type: SocialProfileType
  readonly verified: Scalars['Boolean']
}

export type InstagramProfileCheck =
  | 'ACCOUNT_PRIVATE'
  | 'DUPLICATE'
  | 'ERROR'
  | 'NOT_FOUND'
  | 'REVERIFY_THROUGH_SUPPORT'
  | 'VALID'

export type InstagramProfileCheckResult = {
  readonly __typename: 'InstagramProfileCheckResult'
  readonly code: InstagramProfileCheck
  readonly data?: Maybe<InstagramProfileCheckResultData>
}

export type InstagramProfileCheckResultData = {
  readonly __typename: 'InstagramProfileCheckResultData'
  readonly bioDescription?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly followerCount?: Maybe<Scalars['Int']>
  readonly followingCount?: Maybe<Scalars['Int']>
  readonly id: Scalars['String']
  readonly isPrivate?: Maybe<Scalars['Boolean']>
  readonly profileImage?: Maybe<Scalars['String']>
  readonly profileLink?: Maybe<Scalars['String']>
  readonly profileName: Scalars['String']
}

export type InstagramProfileConfirmResult = {
  readonly __typename: 'InstagramProfileConfirmResult'
  readonly code: InstagramProfileCheck
  readonly data?: Maybe<InstagramProfileCheckResultData>
  /** if the check is valid, it will be below */
  readonly profile?: Maybe<InstagramProfile>
}

export type JoinCommunityCode =
  | 'ACTIVE_BOUNTY_EXISTS'
  | 'BOUNTY_GRANTED'
  | 'CREATOR_LIMITED'
  | 'STORE_NOT_ACTIVE'

export type JoinCommunityResult = {
  readonly __typename: 'JoinCommunityResult'
  readonly bounty?: Maybe<UserBounty>
  readonly code: JoinCommunityCode
  readonly success: Scalars['Boolean']
}

export type Message = {
  readonly __typename: 'Message'
  readonly id: Scalars['String']
  readonly isSystem: Scalars['Boolean']
  readonly message: Scalars['String']
  readonly meta?: Maybe<ChatMessageMetaResult>
  /** If its not null its marked as read */
  readonly readAt?: Maybe<Scalars['PgDate']>
  readonly sender: MessageSender
  readonly sentAt: Scalars['PgDate']
  readonly type: CreatorNotificationType
}

export type MessageSender = {
  readonly __typename: 'MessageSender'
  /** if "SYSTEM" or userId without a tiktok profile this is empty */
  readonly avatarUrl?: Maybe<Scalars['String']>
  /** Sender id is: storeId, userId, or "SYSTEM" */
  readonly id: Scalars['String']
  /** If "SYSTEM" or userId without a tiktok profile this is empty */
  readonly name?: Maybe<Scalars['String']>
}

export type MinimumPayoutInformation = {
  readonly __typename: 'MinimumPayoutInformation'
  readonly isEligibleForMinimumPayout: Scalars['Boolean']
  readonly minimumPayoutAmount: Scalars['Int']
}

export type Mutation = {
  readonly __typename: 'Mutation'
  readonly acceptBounty: UserBounty
  readonly acceptSparkAdCodeRequest: SparkAdCodeRequest
  readonly addPushToken: Scalars['Boolean']
  /**
   * Returns various error codes if we could not find it
   * Should only submit to confirm if you get back "valid"
   */
  readonly checkInstagramProfileName: InstagramProfileCheckResult
  readonly claimGiftOffer: Scalars['Boolean']
  readonly completeWelcomeFlow: User
  readonly confirmInstagramProfileName: InstagramProfileConfirmResult
  /**
   * This can fail if they already cross posted
   * Returns the new user bountyID so you can direct to it
   */
  readonly createCrossPostBounty: Scalars['String']
  readonly createRetailBounty: CreateRetailBountyResult
  readonly createUserProfileInfo: UserProfileInfo
  readonly deleteUser: DeleteUserResponse
  readonly errorTest: Scalars['Boolean']
  readonly errorTest2: Scalars['Boolean']
  /**
   * There are many reasons why this may fail,
   * this returns various codes
   */
  readonly forceCheckForNewVideo: CheckForNewVideoResult
  readonly forceRefreshPaymentSummary?: Maybe<UserPaymentSummary>
  readonly joinCommunity: JoinCommunityResult
  readonly markBountyOrderDelivered: UserBounty
  /**
   * This is a storeID, it will mark every message as read
   * for a related store
   */
  readonly markChatRead?: Maybe<Scalars['Boolean']>
  /** This is an individual notification id */
  readonly markMessageRead?: Maybe<Scalars['Boolean']>
  readonly redeemGiftOfferBounty: Scalars['Boolean']
  readonly redeemGiftOfferProduct: RedeemGiftOfferProductResult
  readonly requestEmailVerification: EmailVerificationRequestResponse
  readonly requestExtensionForBounty: UserBounty
  readonly startStripeAccountLinking: StripeAccountLinkingResult
  readonly submitEmailVerificationCode: EmailVerificationRequestResponse
  readonly submitPayoutRequest: UserPayoutRequestResponse
  readonly submitPayoutRequestStripe: UserPayoutRequestResponse
  readonly submitUserMediaFeedback: UserMediaFeedback
  readonly termsAccepted: Scalars['Boolean']
  readonly updateNotificationGroup: UserNotificationPreferences
  readonly updateNotificationPreference: UserNotificationPreferences
  readonly updateRetailSurveyAnswers: UserBounty
  readonly updateUser: User
  readonly updateUserProfileInfo: UserProfileInfo
  /** returns true if verified / false if not */
  readonly verifyTikTokAuth: Scalars['Boolean']
}

export type MutationAcceptBountyArgs = {
  bountyContentType?: InputMaybe<UserBountyContentType>
  bountyId: Scalars['String']
  briefId?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
}

export type MutationAcceptSparkAdCodeRequestArgs = {
  code: Scalars['String']
  requestId: Scalars['String']
}

export type MutationAddPushTokenArgs = {
  token: Scalars['String']
}

export type MutationCheckInstagramProfileNameArgs = {
  profileName: Scalars['String']
}

export type MutationClaimGiftOfferArgs = {
  key: Scalars['String']
}

export type MutationConfirmInstagramProfileNameArgs = {
  profileName: Scalars['String']
}

export type MutationCreateCrossPostBountyArgs = {
  bountyContentType: UserBountyContentType
  bountyId: Scalars['String']
}

export type MutationCreateRetailBountyArgs = {
  input: CreateRetailBountyInput
}

export type MutationCreateUserProfileInfoArgs = {
  params: CreateUserProfileInfo
}

export type MutationErrorTestArgs = {
  message: Scalars['String']
}

export type MutationErrorTest2Args = {
  message: Scalars['String']
}

export type MutationForceCheckForNewVideoArgs = {
  bountyId: Scalars['String']
}

export type MutationJoinCommunityArgs = {
  briefId?: InputMaybe<Scalars['String']>
  storeId: Scalars['String']
}

export type MutationMarkBountyOrderDeliveredArgs = {
  bountyId: Scalars['String']
}

export type MutationMarkChatReadArgs = {
  chatId: Scalars['String']
}

export type MutationMarkMessageReadArgs = {
  messageId: Scalars['String']
}

export type MutationRedeemGiftOfferBountyArgs = {
  params?: InputMaybe<RedeemGiftOfferBountyInput>
}

export type MutationRedeemGiftOfferProductArgs = {
  params?: InputMaybe<RedeemGiftOfferProductInput>
}

export type MutationRequestEmailVerificationArgs = {
  email: Scalars['String']
}

export type MutationRequestExtensionForBountyArgs = {
  bountyId: Scalars['String']
}

export type MutationStartStripeAccountLinkingArgs = {
  countryCode: Scalars['String']
}

export type MutationSubmitEmailVerificationCodeArgs = {
  code: Scalars['String']
}

export type MutationSubmitPayoutRequestArgs = {
  params: UserPayoutRequestInput
}

export type MutationSubmitPayoutRequestStripeArgs = {
  params: UserPayoutRequestStripeInput
}

export type MutationSubmitUserMediaFeedbackArgs = {
  userMediaFeedbackInput: UserMediaFeedbackInput
}

export type MutationTermsAcceptedArgs = {
  termName: Scalars['String']
}

export type MutationUpdateNotificationGroupArgs = {
  params: UpdateNotificationGroupInput
}

export type MutationUpdateNotificationPreferenceArgs = {
  params: UpdateNotificationPrefInput
}

export type MutationUpdateRetailSurveyAnswersArgs = {
  surveyAnswers: Scalars['JSON']
  userBountyId: Scalars['String']
}

export type MutationUpdateUserArgs = {
  params: UserInput
}

export type MutationUpdateUserProfileInfoArgs = {
  params: UpdateUserProfileInfo
}

export type NotificationMedium = 'EMAIL' | 'PUSH' | 'SMS'

export type NotificationPrefGroup =
  | 'BOUNTY_FEEDBACK'
  | 'MONEY_AVAILABLE'
  | 'POST_REMINDERS'
  | 'PRODUCT_BOUGHT'
  | 'SPARK_CODES'
  | 'SPECIAL_OFFERS'
  | 'SPECIAL_OFFERS_NETWORK'
  | 'UPDATES_ABOUT_BOUNTY'

export type PayoutForViewsByBountyInput = {
  readonly bountyId: Scalars['String']
  readonly modifiers?: InputMaybe<BountyModifier>
  readonly views: Scalars['Float']
}

export type PayoutForViewsByBountyResponse = {
  readonly __typename: 'PayoutForViewsByBountyResponse'
  /**
   * If a user was gifted a product we subtract that cost from the Bounty. We use this on the client to show them this information in
   * the payout calculator
   */
  readonly costPaymentReduction: Scalars['Float']
  readonly isMinimumPayout?: Maybe<Scalars['Boolean']>
  readonly minimumPayout?: Maybe<Scalars['Float']>
  readonly payout: Scalars['Float']
  readonly views: Scalars['Float']
}

export type PayoutForViewsByStoreInput = {
  readonly modifiers?: InputMaybe<BountyModifier>
  readonly storeId: Scalars['String']
  readonly views: Scalars['Float']
}

export type PayoutForViewsByStoreResponse = {
  readonly __typename: 'PayoutForViewsByStoreResponse'
  readonly isMinimumPayout: Scalars['Boolean']
  readonly minimumPayout: Scalars['Float']
  readonly payout: Scalars['Float']
  readonly views: Scalars['Float']
}

export type ProductInstruction = {
  readonly __typename: 'ProductInstruction'
  readonly createdAt: Scalars['PgDate']
  readonly features?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly headline?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isDefault: Scalars['Boolean']
  readonly name?: Maybe<Scalars['String']>
  readonly storeId: Scalars['String']
  readonly updatedAt: Scalars['PgDate']
}

export type Query = {
  readonly __typename: 'Query'
  readonly bounties: UserBountyResponse
  readonly bounty: UserBounty
  readonly bulkStoreCPMStartingPayout: ReadonlyArray<StoreCpmStartingPayout>
  readonly conversation: Conversation
  readonly conversations: ConversationListResponse
  readonly currentUser: User
  /** Note: this is may not exist, will just return null */
  readonly currentUserProfile?: Maybe<UserProfileInfo>
  readonly giftOffer: GiftOfferResult
  readonly giftOfferDetails: GiftOfferDetailsResult
  readonly giftOffers: ReadonlyArray<GiftOffer>
  /** Instagram profile */
  readonly instagramProfile?: Maybe<InstagramProfile>
  readonly isRetailEnabled: Scalars['Boolean']
  /** NOTE: like TikTok api call */
  readonly latestTikTokUserVideos: ReadonlyArray<TikTokUserVideo_Raw>
  readonly mediaFeedbacks: ReadonlyArray<UserMediaFeedback>
  readonly notificationPreferences: UserNotificationPreferences
  readonly payoutForViewsByBounty: PayoutForViewsByBountyResponse
  readonly payoutForViewsByStore: PayoutForViewsByStoreResponse
  readonly recommendedBounties: ReadonlyArray<UserBounty>
  readonly recommendedStores: ReadonlyArray<ShopifyStorePreview>
  readonly retailLocations: ReadonlyArray<Scalars['String']>
  readonly selectableBriefs: ReadonlyArray<Brief>
  readonly storeCPMStartingPayout: StoreCpmStartingPayout
  readonly stripeAccountInfo: StripeAccountInfo
  /**
   * This should only be called if there is accountExists === true from the
   * stripeAccountInfo endpoint call
   */
  readonly stripeAccountLoginLink: StripeAccountLoginLinkResult
  readonly systemMessages: ReadonlyArray<Message>
  /** Note: it may not exist yet depending on how they signed up */
  readonly tikTokProfile?: Maybe<TikTokProfile>
  /**
   * NOTE: like TikTok api call
   * @deprecated Field no longer supported
   */
  readonly tikTokUserInfoDetails: TikTokUserInfoDetails
  readonly topVideos: ReadonlyArray<TopVideo>
  /** This will not exist if the user has a zero balance / never done any payments */
  readonly userPaymentSummary?: Maybe<UserPaymentSummary>
  readonly userPayments: ReadonlyArray<UserPayment>
  readonly userPayoutRequests: ReadonlyArray<UserPayoutRequest>
}

export type QueryBountiesArgs = {
  params?: InputMaybe<UserBountyInput>
}

export type QueryBountyArgs = {
  id: Scalars['String']
}

export type QueryBulkStoreCpmStartingPayoutArgs = {
  modifiers?: InputMaybe<BountyModifier>
  shopUrls: ReadonlyArray<Scalars['String']>
  startingViews?: InputMaybe<Scalars['Int']>
}

export type QueryConversationArgs = {
  id: Scalars['String']
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type QueryConversationsArgs = {
  params?: InputMaybe<ConversationInput>
}

export type QueryGiftOfferArgs = {
  key: Scalars['String']
}

export type QueryGiftOfferDetailsArgs = {
  key: Scalars['String']
}

export type QueryIsRetailEnabledArgs = {
  storeId: Scalars['String']
}

export type QueryPayoutForViewsByBountyArgs = {
  params: PayoutForViewsByBountyInput
}

export type QueryPayoutForViewsByStoreArgs = {
  params: PayoutForViewsByStoreInput
}

export type QueryRetailLocationsArgs = {
  storeId?: InputMaybe<Scalars['String']>
}

export type QuerySelectableBriefsArgs = {
  storeId: Scalars['String']
}

export type QueryStoreCpmStartingPayoutArgs = {
  modifiers?: InputMaybe<BountyModifier>
  shopUrl: Scalars['String']
  startingViews?: InputMaybe<Scalars['Int']>
}

export type QuerySystemMessagesArgs = {
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}

export type RedeemGiftOfferBountyInput = {
  /** unimplimented, just here for future-proofing */
  readonly bountyProductId?: InputMaybe<Scalars['String']>
  readonly key: Scalars['String']
}

export type RedeemGiftOfferProductErrorCode =
  | 'ADDRESS_INVALID'
  | 'BAD_REQUEST'
  | 'CREATE_ORDER_FAILED'
  | 'EXTERNAL_INVITE_NOT_FOUND'
  | 'OFFER_CLAIMED'
  | 'OFFER_COMPLETED'
  | 'OFFER_DISABLED'
  | 'OFFER_EXPIRED'
  | 'OFFER_INVALID'
  | 'OFFER_NOT_FOR_USER'
  | 'OFFER_NOT_FOUND'
  | 'OFFER_OPEN'
  | 'OFFER_REJECTED'
  | 'ORDER_CREATE_ERROR'
  | 'ORDER_IMPORT_ERROR'
  | 'ORDER_INSUFFICIENT_INVENTORY'
  | 'PROSPECT_NOT_FOUND'
  | 'UNKNOWN_ERROR'
  | 'USER_NOT_FOUND'

export type RedeemGiftOfferProductInput = {
  readonly address: ShopifyApiAddress
  readonly email: Scalars['String']
  readonly key: Scalars['String']
  readonly variants: ReadonlyArray<GiftOfferProductVariantInput>
}

export type RedeemGiftOfferProductResult = {
  readonly __typename: 'RedeemGiftOfferProductResult'
  readonly error?: Maybe<RedeemGiftOfferProductErrorCode>
  readonly message?: Maybe<Scalars['String']>
  readonly success: Scalars['Boolean']
}

export type RetailBrief = {
  readonly __typename: 'RetailBrief'
  readonly features?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly headline?: Maybe<Scalars['String']>
}

export type ShopifyApiAddress = {
  readonly address1: Scalars['String']
  readonly address2?: InputMaybe<Scalars['String']>
  readonly city: Scalars['String']
  readonly country_code: Scalars['String']
  readonly first_name: Scalars['String']
  readonly last_name: Scalars['String']
  readonly phone?: InputMaybe<Scalars['String']>
  readonly province: Scalars['String']
  readonly zip: Scalars['String']
}

export type ShopifyStore = {
  readonly __typename: 'ShopifyStore'
  readonly active: Scalars['Boolean']
  readonly brandedHashtag?: Maybe<Scalars['String']>
  readonly categories: ReadonlyArray<Scalars['String']>
  readonly hasActiveSubscription: Scalars['Boolean']
  readonly id: Scalars['String']
  readonly instagramUsername?: Maybe<Scalars['String']>
  readonly isSnippetEnabled: Scalars['Boolean']
  readonly logoImgLink?: Maybe<Scalars['String']>
  readonly minBountyPayment: Scalars['Float']
  readonly name: Scalars['String']
  readonly retailBrief?: Maybe<RetailBrief>
  readonly retailLocations: ReadonlyArray<Scalars['String']>
  readonly retailLocatorUrl?: Maybe<Scalars['String']>
  readonly shopUrl: Scalars['String']
  readonly tags: ReadonlyArray<Scalars['String']>
  readonly tikTokUsername?: Maybe<Scalars['String']>
}

export type ShopifyStoreDeal = {
  readonly __typename: 'ShopifyStoreDeal'
  readonly description?: Maybe<Scalars['String']>
  readonly endDate?: Maybe<Scalars['PgDate']>
  readonly startDate?: Maybe<Scalars['PgDate']>
  readonly terms?: Maybe<Scalars['String']>
  readonly title: Scalars['String']
}

export type ShopifyStoreOrder = {
  readonly __typename: 'ShopifyStoreOrder'
  readonly bounty?: Maybe<UserBounty>
  readonly cancelledAt?: Maybe<Scalars['PgDate']>
  readonly createdAt: Scalars['PgDate']
  readonly deliveredAt?: Maybe<Scalars['PgDate']>
  readonly firstItemName?: Maybe<Scalars['String']>
  readonly fulfilledAt?: Maybe<Scalars['PgDate']>
  readonly id: Scalars['String']
  readonly inTransitAt?: Maybe<Scalars['PgDate']>
  readonly shippedAt?: Maybe<Scalars['PgDate']>
  readonly status: ShopifyStoreOrder_Status
  readonly storeId: Scalars['String']
  readonly updatedAt: Scalars['PgDate']
}

export type ShopifyStoreOrder_Status =
  | 'CANCELLED'
  | 'DELIVERED'
  | 'FULFILLED'
  | 'IN_TRANSIT'
  | 'NOT_SHIPPED'
  | 'OTHER'
  | 'SHIPPED'

export type ShopifyStorePreview = {
  readonly __typename: 'ShopifyStorePreview'
  readonly deals: ReadonlyArray<ShopifyStoreDeal>
  readonly featured: Scalars['Boolean']
  readonly headerImgLinkPrimary?: Maybe<Scalars['String']>
  readonly headerImgLinkSecondary?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly logo?: Maybe<Scalars['String']>
  readonly minBountyPayment: Scalars['Int']
  readonly name: Scalars['String']
  readonly shopUrl: Scalars['String']
  readonly slug: Scalars['String']
  readonly startingCPM: Scalars['Float']
  readonly startingCPMViews: Scalars['Int']
}

export type ShopifyStoreProduct = {
  readonly __typename: 'ShopifyStoreProduct'
  readonly enabled: Scalars['Boolean']
  readonly id: Scalars['String']
  readonly imgLink?: Maybe<Scalars['String']>
  readonly productId: Scalars['String']
  readonly productInstruction?: Maybe<ProductInstruction>
  readonly productName: Scalars['String']
  readonly productSku?: Maybe<Scalars['String']>
  readonly storeId: Scalars['String']
}

export type SocialMediaPlatform = 'INSTAGRAM' | 'TIKTOK'

export type SocialProfileType = 'INSTAGRAM' | 'TIKTOK'

export type SparkAdCodeRequest = {
  readonly __typename: 'SparkAdCodeRequest'
  readonly amount: Scalars['Float']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly requestLog: ReadonlyArray<SparkAdCodeRequestLog>
  readonly status: SparkAdCodeRequestStatus
  readonly updatedAt: Scalars['PgDate']
  readonly userId: Scalars['String']
}

export type SparkAdCodeRequestLog = {
  readonly __typename: 'SparkAdCodeRequestLog'
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly status: SparkAdCodeRequestStatus
}

export type SparkAdCodeRequestStatus =
  | 'CODE_CONFIRMED'
  | 'CODE_NOT_WORKING'
  | 'CREATED'
  | 'CREATOR_ACCEPTED'
  | 'CREATOR_NOTIFIED'
  | 'CREATOR_REJECTED'
  | 'PAID_OUT'

export type StoreCpmStartingPayout = {
  readonly __typename: 'StoreCPMStartingPayout'
  readonly startingCPM: Scalars['Float']
  readonly startingCPMViews: Scalars['Float']
  readonly storeUrl: Scalars['String']
}

export type StripeAccountInfo = {
  readonly __typename: 'StripeAccountInfo'
  readonly accountExists: Scalars['Boolean']
  readonly accountId?: Maybe<Scalars['String']>
  readonly last4?: Maybe<Scalars['String']>
  readonly payoutsEnabled: Scalars['Boolean']
  readonly pendingBalance: Scalars['Float']
  readonly totalBalance: Scalars['Float']
}

export type StripeAccountLinkingResult = {
  readonly __typename: 'StripeAccountLinkingResult'
  readonly id: Scalars['String']
  readonly url: Scalars['String']
}

export type StripeAccountLoginLinkResult = {
  readonly __typename: 'StripeAccountLoginLinkResult'
  readonly url?: Maybe<Scalars['String']>
}

export type TikTokProfile = {
  readonly __typename: 'TikTokProfile'
  /** These fields should not be used if not verified. */
  readonly displayName?: Maybe<Scalars['String']>
  readonly followerCount?: Maybe<Scalars['Int']>
  readonly id: Scalars['String']
  readonly profileImage: Scalars['String']
  readonly profileLink?: Maybe<Scalars['String']>
  readonly profileName?: Maybe<Scalars['String']>
  readonly type: SocialProfileType
  readonly verified: Scalars['Boolean']
}

export type TikTokUserInfoDetails = {
  readonly __typename: 'TikTokUserInfoDetails'
  /** This avatar only lasts 6 hours - so this makes a live api call every time */
  readonly avatarUrl: Scalars['String']
  readonly bioDescription: Scalars['String']
  readonly displayName: Scalars['String']
  readonly followerCount: Scalars['Int']
  readonly profileLink: Scalars['String']
}

export type TikTokUserVideo_Raw = {
  readonly __typename: 'TikTokUserVideo_RAW'
  readonly comment_count: Scalars['Int']
  readonly cover_image_url: Scalars['String']
  readonly create_time: Scalars['String']
  readonly duration: Scalars['Float']
  readonly embed_html: Scalars['String']
  readonly embed_link: Scalars['String']
  readonly id: Scalars['String']
  readonly like_count: Scalars['Int']
  readonly share_count: Scalars['Int']
  readonly share_url: Scalars['String']
  readonly title: Scalars['String']
  readonly video_description: Scalars['String']
  readonly view_count: Scalars['Int']
}

export type TikTokVideo = {
  readonly __typename: 'TikTokVideo'
  readonly commentCount: Scalars['Int']
  readonly createdAt: Scalars['PgDate']
  readonly feedbackMessage?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly likeCount: Scalars['Int']
  readonly shareCount: Scalars['Int']
  readonly sparkAdCodeRequests: ReadonlyArray<SparkAdCodeRequest>
  readonly tikTokVideoPolls: ReadonlyArray<TikTokVideoPoll>
  readonly updatedAt: Scalars['PgDate']
  readonly videoDescription: Scalars['String']
  readonly videoId: Scalars['String']
  readonly videoTitle: Scalars['String']
  readonly videoUrl: Scalars['String']
  readonly viewCount: Scalars['Int']
}

export type TikTokVideoPoll = {
  readonly __typename: 'TikTokVideoPoll'
  readonly commentCount: Scalars['Int']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly likeCount: Scalars['Int']
  readonly shareCount: Scalars['Int']
  readonly viewCount: Scalars['Int']
}

export type TopVideo = {
  readonly __typename: 'TopVideo'
  readonly commentCount: Scalars['Int']
  readonly createdAt: Scalars['PgDate']
  readonly id: Scalars['String']
  readonly likeCount: Scalars['Int']
  readonly product?: Maybe<TopVideoProduct>
  readonly shareCount: Scalars['Int']
  readonly store: TopVideoStore
  readonly updatedAt: Scalars['PgDate']
  readonly userSocialProfile: TopVideoSocialProfile
  readonly videoDescription: Scalars['String']
  readonly videoId: Scalars['String']
  readonly videoTitle: Scalars['String']
  readonly videoUrl: Scalars['String']
  readonly viewCount: Scalars['Int']
}

export type TopVideoProduct = {
  readonly __typename: 'TopVideoProduct'
  readonly id: Scalars['String']
  readonly imgLink?: Maybe<Scalars['String']>
  readonly productName: Scalars['String']
}

export type TopVideoSocialProfile = {
  readonly __typename: 'TopVideoSocialProfile'
  readonly bioDescription?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly followerCount?: Maybe<Scalars['Int']>
  readonly followingCount?: Maybe<Scalars['Int']>
  readonly id: Scalars['String']
  readonly likesCount?: Maybe<Scalars['Int']>
  readonly profileLink?: Maybe<Scalars['String']>
  readonly profileName: Scalars['String']
  readonly type: SocialMediaPlatform
}

export type TopVideoStore = {
  readonly __typename: 'TopVideoStore'
  readonly categories: ReadonlyArray<Scalars['String']>
  readonly id: Scalars['String']
  readonly logoImgLink?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly shopUrl: Scalars['String']
  readonly tags: ReadonlyArray<Scalars['String']>
}

export type UpdateNotificationGroupInput = {
  readonly group: NotificationPrefGroup
  readonly value: Scalars['Boolean']
}

export type UpdateNotificationPrefInput = {
  readonly group: NotificationPrefGroup
  readonly medium: NotificationMedium
  readonly value: Scalars['Boolean']
}

export type UpdateUserProfileInfo = {
  readonly address1?: InputMaybe<Scalars['String']>
  readonly address2?: InputMaybe<Scalars['String']>
  readonly city?: InputMaybe<Scalars['String']>
  readonly country?: InputMaybe<Scalars['String']>
  readonly firstName?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly lastName?: InputMaybe<Scalars['String']>
  readonly postalCode?: InputMaybe<Scalars['String']>
  readonly province?: InputMaybe<Scalars['String']>
  readonly state?: InputMaybe<Scalars['String']>
  readonly suburb?: InputMaybe<Scalars['String']>
  readonly zip?: InputMaybe<Scalars['String']>
}

export type User = {
  readonly __typename: 'User'
  readonly acceptedLatestTerms: Scalars['Boolean']
  readonly communities?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly createdAt: Scalars['PgDate']
  readonly email?: Maybe<Scalars['String']>
  readonly emailVerified: Scalars['Boolean']
  readonly id: Scalars['String']
  readonly isWelcomeFlowCompleted: Scalars['Boolean']
  readonly lastLogin: Scalars['PgDate']
  readonly sms: Scalars['String']
  readonly smsVerified: Scalars['Boolean']
}

export type UserBounty = {
  readonly __typename: 'UserBounty'
  readonly acceptedAt?: Maybe<Scalars['PgDate']>
  readonly availableToAcceptBounty?: Maybe<AvailableToAcceptBountyType>
  readonly bountyDefaultBrief?: Maybe<ProductInstruction>
  readonly brief?: Maybe<Brief>
  readonly briefId?: Maybe<Scalars['String']>
  readonly canPostToOtherSocials?: Maybe<Scalars['Boolean']>
  readonly completedAt?: Maybe<Scalars['PgDate']>
  readonly contentTypeSubmitted?: Maybe<UserBountyContentType>
  readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
  readonly createdAt: Scalars['PgDate']
  readonly expiresAt?: Maybe<Scalars['PgDate']>
  readonly extensionInformation?: Maybe<UserBountyExpirationExtension>
  readonly id: Scalars['String']
  /** will exist if contentTypeSubmitted is INSTAGRAM_REEL */
  readonly instagramContent?: Maybe<InstagramContent>
  readonly isExpired: Scalars['Boolean']
  readonly isNewPayoutStructure: Scalars['Boolean']
  readonly liveAt?: Maybe<Scalars['PgDate']>
  readonly liveEndsAt?: Maybe<Scalars['PgDate']>
  readonly minimumPayoutInformation?: Maybe<MinimumPayoutInformation>
  readonly name?: Maybe<Scalars['String']>
  readonly order?: Maybe<ShopifyStoreOrder>
  readonly paymentSnapshot?: Maybe<UserBounty_PaymentSnapshot>
  readonly product?: Maybe<ShopifyStoreProduct>
  readonly productId?: Maybe<Scalars['String']>
  /** will exist if the video is approved */
  readonly rating?: Maybe<UserBountyRating>
  readonly rejectedAt?: Maybe<Scalars['PgDate']>
  readonly shopifyStoreOrderId?: Maybe<Scalars['String']>
  /**
   * Computed based on ShopifyStore.customPreferences.showOver21AgeGate = true
   * defaults to false
   */
  readonly showOver21AgeGate: Scalars['Boolean']
  readonly status: UserBounty_Status
  readonly store: ShopifyStore
  readonly storeId: Scalars['String']
  readonly surveyAnswers?: Maybe<Scalars['JSON']>
  /** will exist if contentTypeSubmitted is TIKTOK_VIDEO */
  readonly tikTokVideo?: Maybe<TikTokVideo>
  readonly type: UserBounty_Type
  readonly updatedAt: Scalars['PgDate']
  readonly userId: Scalars['String']
  readonly userPayment?: Maybe<UserPayment>
  readonly userPaymentId?: Maybe<Scalars['String']>
}

export type UserBountyContentType =
  | 'DIRECT_UPLOAD_VIDEO'
  | 'INSTAGRAM_REEL'
  | 'INSTAGRAM_STORY'
  | 'TIKTOK_VIDEO'

export type UserBountyExpirationExtension = {
  readonly __typename: 'UserBountyExpirationExtension'
  readonly availableToExtendBounty?: Maybe<Scalars['Boolean']>
  readonly availableToExtendDate?: Maybe<Scalars['PgDate']>
  readonly reason?: Maybe<UserBountyExpirationExtension_ResponseCode>
}

export type UserBountyExpirationExtension_ResponseCode =
  | 'BOUNTY_INVALID'
  | 'CREATOR_LIMITED'
  | 'NOT_EXPIRED'
  | 'OK'
  | 'PRODUCT_DISABLED'
  | 'STORE_DISABLED_BOUNTY'
  | 'STORE_OVER_MONTHLY_SPEND_BUDGET'
  | 'STORE_SUBSCRIPTION_INACTIVE'

export type UserBountyInput = {
  readonly skip?: InputMaybe<Scalars['Int']>
  readonly take?: InputMaybe<Scalars['Int']>
}

export type UserBountyRating = {
  readonly __typename: 'UserBountyRating'
  readonly bonuses: ReadonlyArray<BountyBonuses>
  readonly feedbackMessage?: Maybe<Scalars['String']>
  readonly penalties: ReadonlyArray<BountyPenalties>
}

export type UserBountyResponse = {
  readonly __typename: 'UserBountyResponse'
  readonly count: Scalars['Int']
  readonly items: ReadonlyArray<UserBounty>
  readonly skip: Scalars['Int']
  readonly take: Scalars['Int']
}

export type UserBounty_PaymentSnapshot = {
  readonly __typename: 'UserBounty_PaymentSnapshot'
  readonly minBountyPayment?: Maybe<Scalars['Float']>
  readonly productGiftConfig?: Maybe<UserBounty_ProductGiftConfig>
}

export type UserBounty_ProductGiftConfig = {
  readonly __typename: 'UserBounty_ProductGiftConfig'
  readonly hasMinPayment?: Maybe<Scalars['Boolean']>
  readonly noPayment?: Maybe<Scalars['Boolean']>
  readonly payPerViews?: Maybe<Scalars['Boolean']>
}

export type UserBounty_Status =
  | 'ACCEPTED'
  | 'APPROVED'
  | 'ERROR'
  | 'HOLD'
  | 'LIVE'
  | 'PENDING'
  | 'REJECTED'
  | 'SATISFIED'

export type UserBounty_Type =
  | 'RETAIL'
  | 'SHOPIFY_AD_HOC'
  | 'SHOPIFY_STORE_ORDER'
  | 'SHOPIFY_STORE_ORDER_GIFT'

export type UserInput = {
  readonly isWelcomeFlowCompleted: Scalars['Boolean']
}

export type UserMediaFeedback = {
  readonly __typename: 'UserMediaFeedback'
  readonly author: UserMediaFeedbackAuthorType
  readonly campaign?: Maybe<Scalars['String']>
  readonly createdAt: Scalars['PgDate']
  readonly experimentId?: Maybe<Scalars['String']>
  readonly fileLink: Scalars['String']
  readonly id: Scalars['String']
  readonly message?: Maybe<Scalars['String']>
  readonly notificationMedium?: Maybe<Scalars['String']>
  readonly responseId?: Maybe<Scalars['String']>
  readonly surveyMedium?: Maybe<Scalars['String']>
  readonly transcript?: Maybe<Scalars['String']>
}

export type UserMediaFeedbackAuthorType = 'COMPANY' | 'USER'

export type UserMediaFeedbackInput = {
  readonly campaign?: InputMaybe<Scalars['String']>
  readonly experimentId?: InputMaybe<Scalars['String']>
  readonly fileLink: Scalars['String']
  readonly message?: InputMaybe<Scalars['String']>
  readonly notificationMedium?: InputMaybe<Scalars['String']>
  readonly responseId?: InputMaybe<Scalars['String']>
  readonly surveyMedium?: InputMaybe<Scalars['String']>
}

export type UserNotificationPreferences = {
  readonly __typename: 'UserNotificationPreferences'
  readonly emailGeneralNotifications: Scalars['Boolean']
  readonly emailNotifications: UserNotificationPreferences_MediumGroupConfig
  readonly id: Scalars['String']
  readonly pushGeneralNotifications: Scalars['Boolean']
  readonly pushNotifications: UserNotificationPreferences_MediumGroupConfig
  readonly smsGeneralNotifications: Scalars['Boolean']
  readonly smsNotifications: UserNotificationPreferences_MediumGroupConfig
}

export type UserNotificationPreferences_MediumGroupConfig = {
  readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
  readonly BOUNTY_FEEDBACK: Scalars['Boolean']
  readonly MONEY_AVAILABLE: Scalars['Boolean']
  readonly POST_REMINDERS: Scalars['Boolean']
  readonly PRODUCT_BOUGHT: Scalars['Boolean']
  readonly SPARK_CODES: Scalars['Boolean']
  readonly SPECIAL_OFFERS: Scalars['Boolean']
  readonly SPECIAL_OFFERS_NETWORK: Scalars['Boolean']
  readonly UPDATES_ABOUT_BOUNTY: Scalars['Boolean']
}

export type UserPayment = {
  readonly __typename: 'UserPayment'
  readonly approvedAt?: Maybe<Scalars['PgDate']>
  readonly createdAt: Scalars['PgDate']
  readonly deniedAt?: Maybe<Scalars['PgDate']>
  readonly estimatedFundsAvailableDate?: Maybe<Scalars['PgDate']>
  readonly failedAt?: Maybe<Scalars['PgDate']>
  readonly failureMessage?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly message: Scalars['String']
  readonly settledAt?: Maybe<Scalars['PgDate']>
  readonly status: UserPayment_Status
  readonly type: UserPayment_Type
  readonly updatedAt: Scalars['PgDate']
  readonly value?: Maybe<Scalars['Float']>
}

export type UserPaymentSummary = {
  readonly __typename: 'UserPaymentSummary'
  readonly availableBalance: Scalars['Float']
  readonly id: Scalars['String']
  readonly lastPayoutDate?: Maybe<Scalars['PgDate']>
  readonly lastWithdrawalDate?: Maybe<Scalars['PgDate']>
  readonly pendingPayments: Scalars['Float']
  readonly pendingWithdrawals: Scalars['Float']
  readonly settledBalance: Scalars['Float']
  readonly totalEarned: Scalars['Float']
  readonly updatedAt?: Maybe<Scalars['PgDate']>
  readonly withdrawnBalance: Scalars['Float']
}

export type UserPayment_Status =
  | 'APPROVED'
  | 'DENIED'
  | 'FAILED'
  | 'PENDING'
  | 'SETTLED'

export type UserPayment_Type =
  | 'BONUS_PAYMENT'
  | 'BOUNTY_PAYMENT'
  | 'CONTENT_RIGHTS'
  | 'FEE'
  | 'SPARK_AD'
  | 'TIP'
  | 'USER_PAYOUT'

export type UserPayoutRequest = {
  readonly __typename: 'UserPayoutRequest'
  /** Positive numbers are expected here */
  readonly amount: Scalars['Float']
  readonly approvedAt?: Maybe<Scalars['PgDate']>
  readonly completedAt?: Maybe<Scalars['PgDate']>
  readonly createdAt: Scalars['PgDate']
  readonly deniedAt?: Maybe<Scalars['PgDate']>
  readonly feedbackMessage?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly payment?: Maybe<UserPayment>
  readonly paymentInfo: Scalars['JSON']
  readonly status: UserPayoutRequest_Status
  readonly type: UserPayoutRequest_Type
  readonly updatedAt: Scalars['PgDate']
  readonly userInfo: Scalars['JSON']
}

export type UserPayoutRequestInput = {
  readonly addressInfo: AddressInfoInput
  readonly amount: Scalars['Float']
  readonly paymentInfo: Scalars['JSON']
  readonly paymentType: UserPayoutRequest_Type
}

export type UserPayoutRequestResponse = {
  readonly __typename: 'UserPayoutRequestResponse'
  readonly code: UserPayoutRequestResponse_Code
  readonly message?: Maybe<Scalars['String']>
  readonly success: Scalars['Boolean']
}

export type UserPayoutRequestResponse_Code =
  | 'BANK_ACCOUNT_NOT_LINKED'
  | 'INSUFFICIENT_FUNDS'
  | 'INVALID_ADDRESS'
  | 'INVALID_SUBMISSION'
  | 'MIN_PAYOUT_THRESHOLD'
  | 'SUCCESS'
  | 'UNVERIFIED_EMAIL'

export type UserPayoutRequestStripeInput = {
  readonly amount: Scalars['Float']
  readonly paymentType: UserPayoutRequest_Type
}

export type UserPayoutRequest_Status =
  | 'APPROVED'
  | 'COMPLETE'
  | 'DENIED'
  | 'PENDING'

export type UserPayoutRequest_Type =
  | 'BITCOIN'
  | 'CASHAPP'
  | 'PAYPAL'
  | 'STRIPE'
  | 'VENMO'

export type UserProfileInfo = {
  readonly __typename: 'UserProfileInfo'
  readonly address1?: Maybe<Scalars['String']>
  readonly address2?: Maybe<Scalars['String']>
  readonly city?: Maybe<Scalars['String']>
  readonly country?: Maybe<Scalars['String']>
  readonly createdAt?: Maybe<Scalars['PgDate']>
  readonly firstName: Scalars['String']
  readonly id: Scalars['String']
  readonly lastName: Scalars['String']
  readonly postalCode?: Maybe<Scalars['String']>
  readonly province?: Maybe<Scalars['String']>
  readonly state?: Maybe<Scalars['String']>
  readonly suburb?: Maybe<Scalars['String']>
  readonly updatedAt?: Maybe<Scalars['PgDate']>
  readonly zip?: Maybe<Scalars['String']>
}

export type SubmitPayoutRequestMutationVariables = Exact<{
  params: UserPayoutRequestInput
}>

export type SubmitPayoutRequestMutation = {
  readonly __typename: 'Mutation'
  readonly submitPayoutRequest: {
    readonly __typename: 'UserPayoutRequestResponse'
    readonly code: UserPayoutRequestResponse_Code
    readonly message?: string | null
  }
}

export type SubmitPayoutRequestStripeMutationVariables = Exact<{
  params: UserPayoutRequestStripeInput
}>

export type SubmitPayoutRequestStripeMutation = {
  readonly __typename: 'Mutation'
  readonly submitPayoutRequestStripe: {
    readonly __typename: 'UserPayoutRequestResponse'
    readonly code: UserPayoutRequestResponse_Code
    readonly message?: string | null
  }
}

export type RequestExtensionForBountyMutationVariables = Exact<{
  bountyId: Scalars['String']
}>

export type RequestExtensionForBountyMutation = {
  readonly __typename: 'Mutation'
  readonly requestExtensionForBounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
  }
}

export type ForceRefreshPaymentSummaryMutationVariables = Exact<{
  [key: string]: never
}>

export type ForceRefreshPaymentSummaryMutation = {
  readonly __typename: 'Mutation'
  readonly forceRefreshPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly settledBalance: number
    readonly withdrawnBalance: number
    readonly pendingPayments: number
    readonly totalEarned: number
    readonly pendingWithdrawals: number
    readonly lastPayoutDate?: any | null
    readonly lastWithdrawalDate?: any | null
    readonly updatedAt?: any | null
  } | null
}

export type StartStripeAccountLinkingMutationVariables = Exact<{
  countryCode: Scalars['String']
}>

export type StartStripeAccountLinkingMutation = {
  readonly __typename: 'Mutation'
  readonly startStripeAccountLinking: {
    readonly __typename: 'StripeAccountLinkingResult'
    readonly id: string
    readonly url: string
  }
}

export type CreateUserProfileInfoMutationVariables = Exact<{
  params: CreateUserProfileInfo
}>

export type CreateUserProfileInfoMutation = {
  readonly __typename: 'Mutation'
  readonly createUserProfileInfo: {
    readonly __typename: 'UserProfileInfo'
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly country?: string | null
    readonly address1?: string | null
    readonly address2?: string | null
    readonly city?: string | null
    readonly state?: string | null
    readonly zip?: string | null
  }
}

export type UpdateUserProfileInfoMutationVariables = Exact<{
  params: UpdateUserProfileInfo
}>

export type UpdateUserProfileInfoMutation = {
  readonly __typename: 'Mutation'
  readonly updateUserProfileInfo: {
    readonly __typename: 'UserProfileInfo'
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly country?: string | null
    readonly address1?: string | null
    readonly address2?: string | null
    readonly city?: string | null
    readonly state?: string | null
    readonly zip?: string | null
  }
}

export type AddPushTokenMutationVariables = Exact<{
  token: Scalars['String']
}>

export type AddPushTokenMutation = {
  readonly __typename: 'Mutation'
  readonly addPushToken: boolean
}

export type RequestEmailVerificationMutationVariables = Exact<{
  email: Scalars['String']
}>

export type RequestEmailVerificationMutation = {
  readonly __typename: 'Mutation'
  readonly requestEmailVerification: {
    readonly __typename: 'EmailVerificationRequestResponse'
    readonly success: boolean
    readonly message: string
    readonly code: string
  }
}

export type SubmitEmailVerificationCodeMutationVariables = Exact<{
  code: Scalars['String']
}>

export type SubmitEmailVerificationCodeMutation = {
  readonly __typename: 'Mutation'
  readonly submitEmailVerificationCode: {
    readonly __typename: 'EmailVerificationRequestResponse'
    readonly success: boolean
    readonly message: string
    readonly code: string
  }
}

export type AcceptBountyMutationVariables = Exact<{
  bountyId: Scalars['String']
  bountyContentType?: InputMaybe<UserBountyContentType>
  briefId?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
}>

export type AcceptBountyMutation = {
  readonly __typename: 'Mutation'
  readonly acceptBounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
}

export type ClaimGiftOfferMutationVariables = Exact<{
  key: Scalars['String']
}>

export type ClaimGiftOfferMutation = {
  readonly __typename: 'Mutation'
  readonly claimGiftOffer: boolean
}

export type RedeemGiftOfferProductMutationVariables = Exact<{
  params: RedeemGiftOfferProductInput
}>

export type RedeemGiftOfferProductMutation = {
  readonly __typename: 'Mutation'
  readonly redeemGiftOfferProduct: {
    readonly __typename: 'RedeemGiftOfferProductResult'
    readonly success: boolean
    readonly error?: RedeemGiftOfferProductErrorCode | null
  }
}

export type RedeemGiftOfferBountyMutationVariables = Exact<{
  params: RedeemGiftOfferBountyInput
}>

export type RedeemGiftOfferBountyMutation = {
  readonly __typename: 'Mutation'
  readonly redeemGiftOfferBounty: boolean
}

export type UpdateNotificationPreferencesMutationVariables = Exact<{
  params: UpdateNotificationPrefInput
}>

export type UpdateNotificationPreferencesMutation = {
  readonly __typename: 'Mutation'
  readonly updateNotificationPreference: {
    readonly __typename: 'UserNotificationPreferences'
    readonly id: string
    readonly smsGeneralNotifications: boolean
    readonly emailGeneralNotifications: boolean
    readonly pushGeneralNotifications: boolean
    readonly smsNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
    readonly emailNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
    readonly pushNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
  }
}

export type AcceptSparkAdCodeRequestMutationVariables = Exact<{
  code: Scalars['String']
  requestId: Scalars['String']
}>

export type AcceptSparkAdCodeRequestMutation = {
  readonly __typename: 'Mutation'
  readonly acceptSparkAdCodeRequest: {
    readonly __typename: 'SparkAdCodeRequest'
    readonly id: string
    readonly status: SparkAdCodeRequestStatus
  }
}

export type MarkBountyOrderDeliveredMutationVariables = Exact<{
  bountyId: Scalars['String']
}>

export type MarkBountyOrderDeliveredMutation = {
  readonly __typename: 'Mutation'
  readonly markBountyOrderDelivered: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
}

export type ForceCheckForNewVideoMutationVariables = Exact<{
  bountyId: Scalars['String']
}>

export type ForceCheckForNewVideoMutation = {
  readonly __typename: 'Mutation'
  readonly forceCheckForNewVideo: {
    readonly __typename: 'CheckForNewVideoResult'
    readonly code: CheckForNewVideoResultCode
    readonly message?: string | null
  }
}

export type CompleteWelcomeFlowMutationVariables = Exact<{
  [key: string]: never
}>

export type CompleteWelcomeFlowMutation = {
  readonly __typename: 'Mutation'
  readonly completeWelcomeFlow: {
    readonly __typename: 'User'
    readonly id: string
    readonly isWelcomeFlowCompleted: boolean
  }
}

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>

export type DeleteUserMutation = {
  readonly __typename: 'Mutation'
  readonly deleteUser: {
    readonly __typename: 'DeleteUserResponse'
    readonly code: string
    readonly message: string
    readonly success: boolean
  }
}

export type CheckInstagramProfileNameMutationVariables = Exact<{
  profileName: Scalars['String']
}>

export type CheckInstagramProfileNameMutation = {
  readonly __typename: 'Mutation'
  readonly checkInstagramProfileName: {
    readonly __typename: 'InstagramProfileCheckResult'
    readonly code: InstagramProfileCheck
    readonly data?: {
      readonly __typename: 'InstagramProfileCheckResultData'
      readonly id: string
      readonly profileName: string
      readonly displayName?: string | null
      readonly bioDescription?: string | null
      readonly profileImage?: string | null
      readonly followerCount?: number | null
      readonly followingCount?: number | null
    } | null
  }
}

export type ConfirmInstagramProfileNameMutationVariables = Exact<{
  profileName: Scalars['String']
}>

export type ConfirmInstagramProfileNameMutation = {
  readonly __typename: 'Mutation'
  readonly confirmInstagramProfileName: {
    readonly __typename: 'InstagramProfileConfirmResult'
    readonly code: InstagramProfileCheck
    readonly data?: {
      readonly __typename: 'InstagramProfileCheckResultData'
      readonly id: string
      readonly profileName: string
      readonly displayName?: string | null
    } | null
    readonly profile?: {
      readonly __typename: 'InstagramProfile'
      readonly id: string
      readonly profileName: string
      readonly displayName?: string | null
      readonly verified: boolean
    } | null
  }
}

export type CreateCrossPostBountyMutationVariables = Exact<{
  bountyId: Scalars['String']
  bountyContentType: UserBountyContentType
}>

export type CreateCrossPostBountyMutation = {
  readonly __typename: 'Mutation'
  readonly createCrossPostBounty: string
}

export type SubmitUserMediaFeedbackMutationVariables = Exact<{
  userMediaFeedbackInput: UserMediaFeedbackInput
}>

export type SubmitUserMediaFeedbackMutation = {
  readonly __typename: 'Mutation'
  readonly submitUserMediaFeedback: {
    readonly __typename: 'UserMediaFeedback'
    readonly id: string
    readonly experimentId?: string | null
    readonly fileLink: string
  }
}

export type TermsAcceptedMutationVariables = Exact<{
  termName: Scalars['String']
}>

export type TermsAcceptedMutation = {
  readonly __typename: 'Mutation'
  readonly termsAccepted: boolean
}

export type SelectableBriefsQueryVariables = Exact<{
  storeId: Scalars['String']
}>

export type SelectableBriefsQuery = {
  readonly __typename: 'Query'
  readonly selectableBriefs: ReadonlyArray<{
    readonly __typename: 'Brief'
    readonly id: string
    readonly name: string
    readonly content: string
    readonly videoUrl?: string | null
    readonly paymentSnapshot?: any | null
  }>
}

export type RequireUpdatedTermsQueryVariables = Exact<{ [key: string]: never }>

export type RequireUpdatedTermsQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly isWelcomeFlowCompleted: boolean
    readonly emailVerified: boolean
    readonly acceptedLatestTerms: boolean
  }
}

export type RequireVerifiedInstagramQueryVariables = Exact<{
  [key: string]: never
}>

export type RequireVerifiedInstagramQuery = {
  readonly __typename: 'Query'
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
  } | null
}

export type VerifyTikTokAuthMutationVariables = Exact<{ [key: string]: never }>

export type VerifyTikTokAuthMutation = {
  readonly __typename: 'Mutation'
  readonly verifyTikTokAuth: boolean
}

export type RequireWelcomeFlowQueryVariables = Exact<{ [key: string]: never }>

export type RequireWelcomeFlowQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly verified: boolean
  } | null
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly isWelcomeFlowCompleted: boolean
    readonly emailVerified: boolean
  }
}

export type BountyListQueryVariables = Exact<{
  params?: InputMaybe<UserBountyInput>
}>

export type BountyListQuery = {
  readonly __typename: 'Query'
  readonly bounties: {
    readonly __typename: 'UserBountyResponse'
    readonly count: number
    readonly skip: number
    readonly take: number
    readonly items: ReadonlyArray<{
      readonly __typename: 'UserBounty'
      readonly id: string
      readonly createdAt: any
      readonly isExpired: boolean
      readonly type: UserBounty_Type
      readonly status: UserBounty_Status
      readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
      readonly contentTypeSubmitted?: UserBountyContentType | null
      readonly briefId?: string | null
      readonly order?: {
        readonly __typename: 'ShopifyStoreOrder'
        readonly status: ShopifyStoreOrder_Status
      } | null
      readonly product?: {
        readonly __typename: 'ShopifyStoreProduct'
        readonly id: string
        readonly productName: string
        readonly imgLink?: string | null
      } | null
      readonly store: {
        readonly __typename: 'ShopifyStore'
        readonly id: string
        readonly name: string
        readonly logoImgLink?: string | null
      }
      readonly availableToAcceptBounty?: {
        readonly __typename: 'AvailableToAcceptBountyType'
        readonly isValid: boolean
        readonly validationCode: AvailableToAcceptBounty_ValidationCode
      } | null
      readonly brief?: {
        readonly __typename: 'Brief'
        readonly name: string
        readonly content: string
        readonly paymentSnapshot?: any | null
      } | null
      readonly minimumPayoutInformation?: {
        readonly __typename: 'MinimumPayoutInformation'
        readonly minimumPayoutAmount: number
      } | null
    }>
  }
}

export type BountyOverviewPartsFragment = {
  readonly __typename: 'UserBounty'
  readonly id: string
  readonly createdAt: any
  readonly isExpired: boolean
  readonly type: UserBounty_Type
  readonly status: UserBounty_Status
  readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
  readonly contentTypeSubmitted?: UserBountyContentType | null
  readonly briefId?: string | null
  readonly order?: {
    readonly __typename: 'ShopifyStoreOrder'
    readonly status: ShopifyStoreOrder_Status
  } | null
  readonly product?: {
    readonly __typename: 'ShopifyStoreProduct'
    readonly id: string
    readonly productName: string
    readonly imgLink?: string | null
  } | null
  readonly store: {
    readonly __typename: 'ShopifyStore'
    readonly id: string
    readonly name: string
    readonly logoImgLink?: string | null
  }
  readonly availableToAcceptBounty?: {
    readonly __typename: 'AvailableToAcceptBountyType'
    readonly isValid: boolean
    readonly validationCode: AvailableToAcceptBounty_ValidationCode
  } | null
  readonly brief?: {
    readonly __typename: 'Brief'
    readonly name: string
    readonly content: string
    readonly paymentSnapshot?: any | null
  } | null
  readonly minimumPayoutInformation?: {
    readonly __typename: 'MinimumPayoutInformation'
    readonly minimumPayoutAmount: number
  } | null
}

export type BountyPartsFragment = {
  readonly __typename: 'UserBounty'
  readonly id: string
  readonly acceptedAt?: any | null
  readonly liveAt?: any | null
  readonly createdAt: any
  readonly liveEndsAt?: any | null
  readonly completedAt?: any | null
  readonly rejectedAt?: any | null
  readonly expiresAt?: any | null
  readonly userId: string
  readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
  readonly contentTypeSubmitted?: UserBountyContentType | null
  readonly showOver21AgeGate: boolean
  readonly type: UserBounty_Type
  readonly status: UserBounty_Status
  readonly isExpired: boolean
  readonly isNewPayoutStructure: boolean
  readonly surveyAnswers?: any | null
  readonly briefId?: string | null
  readonly canPostToOtherSocials?: boolean | null
  readonly tikTokVideo?: {
    readonly __typename: 'TikTokVideo'
    readonly id: string
    readonly videoUrl: string
    readonly videoId: string
    readonly videoTitle: string
    readonly videoDescription: string
    readonly likeCount: number
    readonly viewCount: number
    readonly sparkAdCodeRequests: ReadonlyArray<{
      readonly __typename: 'SparkAdCodeRequest'
      readonly id: string
      readonly status: SparkAdCodeRequestStatus
      readonly amount: number
      readonly createdAt: any
      readonly requestLog: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequestLog'
        readonly status: SparkAdCodeRequestStatus
        readonly message?: string | null
      }>
    }>
  } | null
  readonly instagramContent?: {
    readonly __typename: 'InstagramContent'
    readonly id: string
    readonly type: InstagramContentType
    readonly userBountyId: string
    readonly url: string
    readonly imageUrl: string
    readonly title: string
    readonly description?: string | null
    readonly contentId: string
    readonly likeCount: number
    readonly viewCount: number
    readonly commentCount: number
    readonly shareCount: number
    readonly createdAt: any
  } | null
  readonly rating?: {
    readonly __typename: 'UserBountyRating'
    readonly feedbackMessage?: string | null
  } | null
  readonly userPayment?: {
    readonly __typename: 'UserPayment'
    readonly id: string
    readonly value?: number | null
    readonly type: UserPayment_Type
    readonly status: UserPayment_Status
    readonly approvedAt?: any | null
    readonly settledAt?: any | null
    readonly deniedAt?: any | null
    readonly failedAt?: any | null
    readonly message: string
    readonly failureMessage?: string | null
    readonly estimatedFundsAvailableDate?: any | null
  } | null
  readonly order?: {
    readonly __typename: 'ShopifyStoreOrder'
    readonly id: string
    readonly status: ShopifyStoreOrder_Status
    readonly createdAt: any
    readonly fulfilledAt?: any | null
    readonly shippedAt?: any | null
    readonly inTransitAt?: any | null
    readonly deliveredAt?: any | null
    readonly cancelledAt?: any | null
  } | null
  readonly product?: {
    readonly __typename: 'ShopifyStoreProduct'
    readonly id: string
    readonly productName: string
    readonly imgLink?: string | null
    readonly productInstruction?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
  } | null
  readonly store: {
    readonly __typename: 'ShopifyStore'
    readonly id: string
    readonly name: string
    readonly logoImgLink?: string | null
    readonly tikTokUsername?: string | null
    readonly instagramUsername?: string | null
    readonly brandedHashtag?: string | null
    readonly shopUrl: string
    readonly retailLocations: ReadonlyArray<string>
    readonly retailLocatorUrl?: string | null
    readonly retailBrief?: {
      readonly __typename: 'RetailBrief'
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
  }
  readonly availableToAcceptBounty?: {
    readonly __typename: 'AvailableToAcceptBountyType'
    readonly isValid: boolean
    readonly validationCode: AvailableToAcceptBounty_ValidationCode
  } | null
  readonly extensionInformation?: {
    readonly __typename: 'UserBountyExpirationExtension'
    readonly availableToExtendBounty?: boolean | null
    readonly availableToExtendDate?: any | null
    readonly reason?: UserBountyExpirationExtension_ResponseCode | null
  } | null
  readonly minimumPayoutInformation?: {
    readonly __typename: 'MinimumPayoutInformation'
    readonly isEligibleForMinimumPayout: boolean
    readonly minimumPayoutAmount: number
  } | null
  readonly bountyDefaultBrief?: {
    readonly __typename: 'ProductInstruction'
    readonly id: string
    readonly storeId: string
    readonly name?: string | null
    readonly headline?: string | null
    readonly features?: ReadonlyArray<string> | null
  } | null
  readonly paymentSnapshot?: {
    readonly __typename: 'UserBounty_PaymentSnapshot'
    readonly minBountyPayment?: number | null
    readonly productGiftConfig?: {
      readonly __typename: 'UserBounty_ProductGiftConfig'
      readonly hasMinPayment?: boolean | null
      readonly payPerViews?: boolean | null
      readonly noPayment?: boolean | null
    } | null
  } | null
  readonly brief?: {
    readonly __typename: 'Brief'
    readonly id: string
    readonly name: string
    readonly slug: string
    readonly content: string
    readonly videoUrl?: string | null
    readonly isDefault: boolean
    readonly createdAt: any
    readonly paymentSnapshot?: any | null
  } | null
}

export type BountyAcceptBriefQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptBriefQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly profileImage: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
}

export type BountyAcceptContentTypeQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptContentTypeQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly profileImage: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
}

export type BountyAcceptGuidelineQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptGuidelineQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly profileImage: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
}

export type BountyAcceptRulesQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptRulesQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly profileImage: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
}

export type BountyAcceptSelectBriefQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptSelectBriefQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
    readonly profileImage: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly profileName: string
    readonly verified: boolean
    readonly id: string
    readonly type: SocialProfileType
  } | null
}

export type BountyAcceptSurveyQueryVariables = Exact<{
  id: Scalars['String']
}>

export type BountyAcceptSurveyQuery = {
  readonly __typename: 'Query'
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
}

export type UpdateSurveyAnswersMutationVariables = Exact<{
  id: Scalars['String']
  surveyAnswers: Scalars['JSON']
}>

export type UpdateSurveyAnswersMutation = {
  readonly __typename: 'Mutation'
  readonly updateRetailSurveyAnswers: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
}

export type BountyDetailQueryVariables = Exact<{
  bountyId: Scalars['String']
}>

export type BountyDetailQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly id: string
    readonly profileName?: string | null
    readonly profileImage: string
    readonly profileLink?: string | null
    readonly verified: boolean
    readonly type: SocialProfileType
  } | null
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly id: string
    readonly profileName: string
    readonly displayName?: string | null
    readonly profileLink?: string | null
    readonly verified: boolean
    readonly type: SocialProfileType
  } | null
  readonly bounty: {
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly acceptedAt?: any | null
    readonly liveAt?: any | null
    readonly createdAt: any
    readonly liveEndsAt?: any | null
    readonly completedAt?: any | null
    readonly rejectedAt?: any | null
    readonly expiresAt?: any | null
    readonly userId: string
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly showOver21AgeGate: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly isExpired: boolean
    readonly isNewPayoutStructure: boolean
    readonly surveyAnswers?: any | null
    readonly briefId?: string | null
    readonly canPostToOtherSocials?: boolean | null
    readonly tikTokVideo?: {
      readonly __typename: 'TikTokVideo'
      readonly id: string
      readonly videoUrl: string
      readonly videoId: string
      readonly videoTitle: string
      readonly videoDescription: string
      readonly likeCount: number
      readonly viewCount: number
      readonly sparkAdCodeRequests: ReadonlyArray<{
        readonly __typename: 'SparkAdCodeRequest'
        readonly id: string
        readonly status: SparkAdCodeRequestStatus
        readonly amount: number
        readonly createdAt: any
        readonly requestLog: ReadonlyArray<{
          readonly __typename: 'SparkAdCodeRequestLog'
          readonly status: SparkAdCodeRequestStatus
          readonly message?: string | null
        }>
      }>
    } | null
    readonly instagramContent?: {
      readonly __typename: 'InstagramContent'
      readonly id: string
      readonly type: InstagramContentType
      readonly userBountyId: string
      readonly url: string
      readonly imageUrl: string
      readonly title: string
      readonly description?: string | null
      readonly contentId: string
      readonly likeCount: number
      readonly viewCount: number
      readonly commentCount: number
      readonly shareCount: number
      readonly createdAt: any
    } | null
    readonly rating?: {
      readonly __typename: 'UserBountyRating'
      readonly feedbackMessage?: string | null
    } | null
    readonly userPayment?: {
      readonly __typename: 'UserPayment'
      readonly id: string
      readonly value?: number | null
      readonly type: UserPayment_Type
      readonly status: UserPayment_Status
      readonly approvedAt?: any | null
      readonly settledAt?: any | null
      readonly deniedAt?: any | null
      readonly failedAt?: any | null
      readonly message: string
      readonly failureMessage?: string | null
      readonly estimatedFundsAvailableDate?: any | null
    } | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly id: string
      readonly status: ShopifyStoreOrder_Status
      readonly createdAt: any
      readonly fulfilledAt?: any | null
      readonly shippedAt?: any | null
      readonly inTransitAt?: any | null
      readonly deliveredAt?: any | null
      readonly cancelledAt?: any | null
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
      readonly productInstruction?: {
        readonly __typename: 'ProductInstruction'
        readonly id: string
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
      readonly tikTokUsername?: string | null
      readonly instagramUsername?: string | null
      readonly brandedHashtag?: string | null
      readonly shopUrl: string
      readonly retailLocations: ReadonlyArray<string>
      readonly retailLocatorUrl?: string | null
      readonly retailBrief?: {
        readonly __typename: 'RetailBrief'
        readonly headline?: string | null
        readonly features?: ReadonlyArray<string> | null
      } | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly extensionInformation?: {
      readonly __typename: 'UserBountyExpirationExtension'
      readonly availableToExtendBounty?: boolean | null
      readonly availableToExtendDate?: any | null
      readonly reason?: UserBountyExpirationExtension_ResponseCode | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly isEligibleForMinimumPayout: boolean
      readonly minimumPayoutAmount: number
    } | null
    readonly bountyDefaultBrief?: {
      readonly __typename: 'ProductInstruction'
      readonly id: string
      readonly storeId: string
      readonly name?: string | null
      readonly headline?: string | null
      readonly features?: ReadonlyArray<string> | null
    } | null
    readonly paymentSnapshot?: {
      readonly __typename: 'UserBounty_PaymentSnapshot'
      readonly minBountyPayment?: number | null
      readonly productGiftConfig?: {
        readonly __typename: 'UserBounty_ProductGiftConfig'
        readonly hasMinPayment?: boolean | null
        readonly payPerViews?: boolean | null
        readonly noPayment?: boolean | null
      } | null
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly id: string
      readonly name: string
      readonly slug: string
      readonly content: string
      readonly videoUrl?: string | null
      readonly isDefault: boolean
      readonly createdAt: any
      readonly paymentSnapshot?: any | null
    } | null
  }
}

export type JoinCommunityMutationVariables = Exact<{
  storeId: Scalars['String']
}>

export type JoinCommunityMutation = {
  readonly __typename: 'Mutation'
  readonly joinCommunity: {
    readonly __typename: 'JoinCommunityResult'
    readonly success: boolean
    readonly code: JoinCommunityCode
    readonly bounty?: {
      readonly __typename: 'UserBounty'
      readonly id: string
    } | null
  }
}

export type ConversationDetailQueryVariables = Exact<{
  id: Scalars['String']
  skip?: InputMaybe<Scalars['Int']>
  take?: InputMaybe<Scalars['Int']>
}>

export type ConversationDetailQuery = {
  readonly __typename: 'Query'
  readonly conversation: {
    readonly __typename: 'Conversation'
    readonly avatarUrl?: string | null
    readonly id: string
    readonly name: string
    readonly unreadCount?: number | null
    readonly lastReceivedActionableMessage?: {
      readonly __typename: 'Message'
      readonly id: string
      readonly isSystem: boolean
      readonly message: string
      readonly type: CreatorNotificationType
      readonly sentAt: any
    } | null
    readonly latestMessage?: {
      readonly __typename: 'Message'
      readonly id: string
      readonly isSystem: boolean
      readonly message: string
      readonly type: CreatorNotificationType
      readonly sentAt: any
    } | null
    readonly messages?: ReadonlyArray<{
      readonly __typename: 'Message'
      readonly id: string
      readonly isSystem: boolean
      readonly message: string
      readonly readAt?: any | null
      readonly type: CreatorNotificationType
      readonly sentAt: any
      readonly meta?:
        | {
            readonly __typename: 'ChatMessageMetaGiftOffer'
            readonly createdAt: any
            readonly giftType: GiftOfferType
            readonly giftValue?: number | null
            readonly id: string
            readonly offerStatus: GiftOfferStatus
          }
        | {
            readonly __typename: 'ChatMessageMetaSparkCode'
            readonly bountyId: string
            readonly createdAt: any
            readonly id: string
            readonly message?: string | null
            readonly sparkCodeAmount: number
            readonly sparkCodeStatus: SparkAdCodeRequestStatus
          }
        | null
      readonly sender: {
        readonly __typename: 'MessageSender'
        readonly avatarUrl?: string | null
        readonly id: string
        readonly name?: string | null
      }
    }> | null
  }
}

export type MessagePartsFragment = {
  readonly __typename: 'Message'
  readonly id: string
  readonly isSystem: boolean
  readonly message: string
  readonly readAt?: any | null
  readonly type: CreatorNotificationType
  readonly sentAt: any
  readonly meta?:
    | {
        readonly __typename: 'ChatMessageMetaGiftOffer'
        readonly createdAt: any
        readonly giftType: GiftOfferType
        readonly giftValue?: number | null
        readonly id: string
        readonly offerStatus: GiftOfferStatus
      }
    | {
        readonly __typename: 'ChatMessageMetaSparkCode'
        readonly bountyId: string
        readonly createdAt: any
        readonly id: string
        readonly message?: string | null
        readonly sparkCodeAmount: number
        readonly sparkCodeStatus: SparkAdCodeRequestStatus
      }
    | null
  readonly sender: {
    readonly __typename: 'MessageSender'
    readonly avatarUrl?: string | null
    readonly id: string
    readonly name?: string | null
  }
}

export type ConversationsQueryVariables = Exact<{
  params: ConversationInput
}>

export type ConversationsQuery = {
  readonly __typename: 'Query'
  readonly conversations: {
    readonly __typename: 'ConversationListResponse'
    readonly count: number
    readonly skip: number
    readonly take: number
    readonly items: ReadonlyArray<{
      readonly __typename: 'Conversation'
      readonly avatarUrl?: string | null
      readonly id: string
      readonly name: string
      readonly unreadCount?: number | null
      readonly lastReceivedActionableMessage?: {
        readonly __typename: 'Message'
        readonly id: string
        readonly isSystem: boolean
        readonly message: string
        readonly sentAt: any
        readonly type: CreatorNotificationType
      } | null
      readonly latestMessage?: {
        readonly __typename: 'Message'
        readonly id: string
        readonly isSystem: boolean
        readonly message: string
        readonly sentAt: any
        readonly type: CreatorNotificationType
      } | null
    }>
  }
}

export type ConversationPartsFragment = {
  readonly __typename: 'Conversation'
  readonly avatarUrl?: string | null
  readonly id: string
  readonly name: string
  readonly unreadCount?: number | null
  readonly lastReceivedActionableMessage?: {
    readonly __typename: 'Message'
    readonly id: string
    readonly isSystem: boolean
    readonly message: string
    readonly sentAt: any
    readonly type: CreatorNotificationType
  } | null
  readonly latestMessage?: {
    readonly __typename: 'Message'
    readonly id: string
    readonly isSystem: boolean
    readonly message: string
    readonly sentAt: any
    readonly type: CreatorNotificationType
  } | null
}

export type GiftRedeemQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GiftRedeemQuery = {
  readonly __typename: 'Query'
  readonly currentUserProfile?: {
    readonly __typename: 'UserProfileInfo'
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly address1?: string | null
    readonly address2?: string | null
    readonly city?: string | null
    readonly state?: string | null
    readonly zip?: string | null
    readonly country?: string | null
  } | null
  readonly giftOffer:
    | {
        readonly __typename: 'GiftOfferBounty'
        readonly minBountyPayment: number
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly createdAt: any
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
      }
    | {
        readonly __typename: 'GiftOfferError'
        readonly message?: string | null
        readonly code: GiftOfferErrorCode
      }
    | {
        readonly __typename: 'GiftOfferProduct'
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly createdAt: any
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
        readonly products: ReadonlyArray<{
          readonly __typename: 'GiftOfferProductOption'
          readonly id: string
          readonly imageUrl?: string | null
          readonly name: string
        }>
        readonly paymentSnapshot?: {
          readonly __typename: 'UserBounty_PaymentSnapshot'
          readonly minBountyPayment?: number | null
          readonly productGiftConfig?: {
            readonly __typename: 'UserBounty_ProductGiftConfig'
            readonly hasMinPayment?: boolean | null
            readonly payPerViews?: boolean | null
            readonly noPayment?: boolean | null
          } | null
        } | null
      }
  readonly giftOfferDetails:
    | {
        readonly __typename: 'GiftOfferBountyDetails'
        readonly id: string
        readonly storeName: string
        readonly minBountyPayment: number
      }
    | { readonly __typename: 'GiftOfferError' }
    | {
        readonly __typename: 'GiftOfferProductDetails'
        readonly email?: string | null
        readonly products: ReadonlyArray<{
          readonly __typename: 'GiftOfferProductDetailOption'
          readonly id: string
          readonly name: string
          readonly storeName: string
          readonly quantity: number
          readonly productLink?: string | null
          readonly imageUrl?: string | null
          readonly variants: ReadonlyArray<{
            readonly __typename: 'GiftOfferProductVariant'
            readonly id: string
            readonly name: string
          }>
        }>
      }
}

export type GiftSuccessQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GiftSuccessQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly isWelcomeFlowCompleted: boolean
  }
  readonly giftOffer:
    | {
        readonly __typename: 'GiftOfferBounty'
        readonly minBountyPayment: number
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly refType?: GiftOfferRefType | null
        readonly key: string
        readonly storeId: string
        readonly id: string
      }
    | {
        readonly __typename: 'GiftOfferError'
        readonly message?: string | null
        readonly code: GiftOfferErrorCode
      }
    | {
        readonly __typename: 'GiftOfferProduct'
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly refType?: GiftOfferRefType | null
        readonly key: string
        readonly storeId: string
        readonly id: string
      }
}

export type GiftWelcomeAuthedQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GiftWelcomeAuthedQuery = {
  readonly __typename: 'Query'
  readonly giftOffer:
    | {
        readonly __typename: 'GiftOfferBounty'
        readonly minBountyPayment: number
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly createdAt: any
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
      }
    | {
        readonly __typename: 'GiftOfferError'
        readonly message?: string | null
        readonly code: GiftOfferErrorCode
      }
    | {
        readonly __typename: 'GiftOfferProduct'
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly createdAt: any
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
        readonly products: ReadonlyArray<{
          readonly __typename: 'GiftOfferProductOption'
          readonly id: string
          readonly imageUrl?: string | null
          readonly name: string
        }>
      }
}

export type UseClaimGiftQueryVariables = Exact<{
  key: Scalars['String']
}>

export type UseClaimGiftQuery = {
  readonly __typename: 'Query'
  readonly giftOffer:
    | {
        readonly __typename: 'GiftOfferBounty'
        readonly minBountyPayment: number
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly createdAt: any
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
      }
    | {
        readonly __typename: 'GiftOfferError'
        readonly message?: string | null
        readonly code: GiftOfferErrorCode
      }
    | {
        readonly __typename: 'GiftOfferProduct'
        readonly type: GiftOfferType
        readonly status: GiftOfferStatus
        readonly refType?: GiftOfferRefType | null
        readonly storeName?: string | null
        readonly createdAt: any
        readonly key: string
        readonly storeId: string
        readonly id: string
        readonly offerValue?: number | null
        readonly message?: string | null
        readonly shopUrl: string
        readonly storeLogo?: string | null
        readonly products: ReadonlyArray<{
          readonly __typename: 'GiftOfferProductOption'
          readonly id: string
          readonly imageUrl?: string | null
          readonly name: string
        }>
      }
}

export type HomeQueryVariables = Exact<{
  params?: InputMaybe<UserBountyInput>
}>

export type HomeQuery = {
  readonly __typename: 'Query'
  readonly bounties: {
    readonly __typename: 'UserBountyResponse'
    readonly count: number
    readonly items: ReadonlyArray<{
      readonly __typename: 'UserBounty'
      readonly id: string
      readonly status: UserBounty_Status
      readonly store: {
        readonly __typename: 'ShopifyStore'
        readonly id: string
        readonly name: string
        readonly logoImgLink?: string | null
      }
    }>
  }
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly communities?: ReadonlyArray<string> | null
  }
  readonly recommendedBounties: ReadonlyArray<{
    readonly __typename: 'UserBounty'
    readonly id: string
    readonly createdAt: any
    readonly isExpired: boolean
    readonly type: UserBounty_Type
    readonly status: UserBounty_Status
    readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
    readonly contentTypeSubmitted?: UserBountyContentType | null
    readonly briefId?: string | null
    readonly order?: {
      readonly __typename: 'ShopifyStoreOrder'
      readonly status: ShopifyStoreOrder_Status
    } | null
    readonly product?: {
      readonly __typename: 'ShopifyStoreProduct'
      readonly id: string
      readonly productName: string
      readonly imgLink?: string | null
    } | null
    readonly store: {
      readonly __typename: 'ShopifyStore'
      readonly id: string
      readonly name: string
      readonly logoImgLink?: string | null
    }
    readonly availableToAcceptBounty?: {
      readonly __typename: 'AvailableToAcceptBountyType'
      readonly isValid: boolean
      readonly validationCode: AvailableToAcceptBounty_ValidationCode
    } | null
    readonly brief?: {
      readonly __typename: 'Brief'
      readonly name: string
      readonly content: string
      readonly paymentSnapshot?: any | null
    } | null
    readonly minimumPayoutInformation?: {
      readonly __typename: 'MinimumPayoutInformation'
      readonly minimumPayoutAmount: number
    } | null
  }>
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly pendingPayments: number
    readonly settledBalance: number
    readonly updatedAt?: any | null
  } | null
  readonly recommendedStores: ReadonlyArray<{
    readonly __typename: 'ShopifyStorePreview'
    readonly name: string
    readonly shopUrl: string
    readonly id: string
    readonly logo?: string | null
    readonly featured: boolean
    readonly minBountyPayment: number
    readonly startingCPM: number
    readonly startingCPMViews: number
    readonly headerImgLinkPrimary?: string | null
    readonly headerImgLinkSecondary?: string | null
  }>
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly verified: boolean
    readonly profileName?: string | null
    readonly followerCount?: number | null
  } | null
}

export type AuthTikTokQueryVariables = Exact<{ [key: string]: never }>

export type AuthTikTokQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly verified: boolean
    readonly profileName?: string | null
    readonly followerCount?: number | null
  } | null
}

export type ConnectSocialsQueryVariables = Exact<{ [key: string]: never }>

export type ConnectSocialsQuery = {
  readonly __typename: 'Query'
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly id: string
    readonly verified: boolean
  } | null
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly id: string
    readonly verified: boolean
  } | null
}

export type CurrentRewardsQueryVariables = Exact<{ [key: string]: never }>

export type CurrentRewardsQuery = {
  readonly __typename: 'Query'
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly pendingPayments: number
    readonly settledBalance: number
    readonly updatedAt?: any | null
  } | null
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileImage: string
  } | null
}

export type CreateRetailBountyMutationVariables = Exact<{
  storeId: Scalars['String']
}>

export type CreateRetailBountyMutation = {
  readonly __typename: 'Mutation'
  readonly createRetailBounty:
    | {
        readonly __typename: 'CreateRetailBountyError'
        readonly code: CreateRetailBountyErrorCode
        readonly bountyId?: string | null
      }
    | {
        readonly __typename: 'UserBounty'
        readonly id: string
        readonly acceptedAt?: any | null
        readonly liveAt?: any | null
        readonly createdAt: any
        readonly liveEndsAt?: any | null
        readonly completedAt?: any | null
        readonly rejectedAt?: any | null
        readonly expiresAt?: any | null
        readonly userId: string
        readonly contentTypesAllowed: ReadonlyArray<UserBountyContentType>
        readonly contentTypeSubmitted?: UserBountyContentType | null
        readonly showOver21AgeGate: boolean
        readonly type: UserBounty_Type
        readonly status: UserBounty_Status
        readonly isExpired: boolean
        readonly isNewPayoutStructure: boolean
        readonly surveyAnswers?: any | null
        readonly briefId?: string | null
        readonly canPostToOtherSocials?: boolean | null
        readonly tikTokVideo?: {
          readonly __typename: 'TikTokVideo'
          readonly id: string
          readonly videoUrl: string
          readonly videoId: string
          readonly videoTitle: string
          readonly videoDescription: string
          readonly likeCount: number
          readonly viewCount: number
          readonly sparkAdCodeRequests: ReadonlyArray<{
            readonly __typename: 'SparkAdCodeRequest'
            readonly id: string
            readonly status: SparkAdCodeRequestStatus
            readonly amount: number
            readonly createdAt: any
            readonly requestLog: ReadonlyArray<{
              readonly __typename: 'SparkAdCodeRequestLog'
              readonly status: SparkAdCodeRequestStatus
              readonly message?: string | null
            }>
          }>
        } | null
        readonly instagramContent?: {
          readonly __typename: 'InstagramContent'
          readonly id: string
          readonly type: InstagramContentType
          readonly userBountyId: string
          readonly url: string
          readonly imageUrl: string
          readonly title: string
          readonly description?: string | null
          readonly contentId: string
          readonly likeCount: number
          readonly viewCount: number
          readonly commentCount: number
          readonly shareCount: number
          readonly createdAt: any
        } | null
        readonly rating?: {
          readonly __typename: 'UserBountyRating'
          readonly feedbackMessage?: string | null
        } | null
        readonly userPayment?: {
          readonly __typename: 'UserPayment'
          readonly id: string
          readonly value?: number | null
          readonly type: UserPayment_Type
          readonly status: UserPayment_Status
          readonly approvedAt?: any | null
          readonly settledAt?: any | null
          readonly deniedAt?: any | null
          readonly failedAt?: any | null
          readonly message: string
          readonly failureMessage?: string | null
          readonly estimatedFundsAvailableDate?: any | null
        } | null
        readonly order?: {
          readonly __typename: 'ShopifyStoreOrder'
          readonly id: string
          readonly status: ShopifyStoreOrder_Status
          readonly createdAt: any
          readonly fulfilledAt?: any | null
          readonly shippedAt?: any | null
          readonly inTransitAt?: any | null
          readonly deliveredAt?: any | null
          readonly cancelledAt?: any | null
        } | null
        readonly product?: {
          readonly __typename: 'ShopifyStoreProduct'
          readonly id: string
          readonly productName: string
          readonly imgLink?: string | null
          readonly productInstruction?: {
            readonly __typename: 'ProductInstruction'
            readonly id: string
            readonly headline?: string | null
            readonly features?: ReadonlyArray<string> | null
          } | null
        } | null
        readonly store: {
          readonly __typename: 'ShopifyStore'
          readonly id: string
          readonly name: string
          readonly logoImgLink?: string | null
          readonly tikTokUsername?: string | null
          readonly instagramUsername?: string | null
          readonly brandedHashtag?: string | null
          readonly shopUrl: string
          readonly retailLocations: ReadonlyArray<string>
          readonly retailLocatorUrl?: string | null
          readonly retailBrief?: {
            readonly __typename: 'RetailBrief'
            readonly headline?: string | null
            readonly features?: ReadonlyArray<string> | null
          } | null
        }
        readonly availableToAcceptBounty?: {
          readonly __typename: 'AvailableToAcceptBountyType'
          readonly isValid: boolean
          readonly validationCode: AvailableToAcceptBounty_ValidationCode
        } | null
        readonly extensionInformation?: {
          readonly __typename: 'UserBountyExpirationExtension'
          readonly availableToExtendBounty?: boolean | null
          readonly availableToExtendDate?: any | null
          readonly reason?: UserBountyExpirationExtension_ResponseCode | null
        } | null
        readonly minimumPayoutInformation?: {
          readonly __typename: 'MinimumPayoutInformation'
          readonly isEligibleForMinimumPayout: boolean
          readonly minimumPayoutAmount: number
        } | null
        readonly bountyDefaultBrief?: {
          readonly __typename: 'ProductInstruction'
          readonly id: string
          readonly storeId: string
          readonly name?: string | null
          readonly headline?: string | null
          readonly features?: ReadonlyArray<string> | null
        } | null
        readonly paymentSnapshot?: {
          readonly __typename: 'UserBounty_PaymentSnapshot'
          readonly minBountyPayment?: number | null
          readonly productGiftConfig?: {
            readonly __typename: 'UserBounty_ProductGiftConfig'
            readonly hasMinPayment?: boolean | null
            readonly payPerViews?: boolean | null
            readonly noPayment?: boolean | null
          } | null
        } | null
        readonly brief?: {
          readonly __typename: 'Brief'
          readonly id: string
          readonly name: string
          readonly slug: string
          readonly content: string
          readonly videoUrl?: string | null
          readonly isDefault: boolean
          readonly createdAt: any
          readonly paymentSnapshot?: any | null
        } | null
      }
}

export type ManageAccountQueryVariables = Exact<{ [key: string]: never }>

export type ManageAccountQuery = {
  readonly __typename: 'Query'
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly availableBalance: number
    readonly pendingPayments: number
  } | null
}

export type ManageAddressesQueryVariables = Exact<{ [key: string]: never }>

export type ManageAddressesQuery = {
  readonly __typename: 'Query'
  readonly currentUserProfile?: {
    readonly __typename: 'UserProfileInfo'
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly country?: string | null
    readonly address1?: string | null
    readonly address2?: string | null
    readonly city?: string | null
    readonly state?: string | null
    readonly zip?: string | null
  } | null
}

export type SettingsQueryVariables = Exact<{ [key: string]: never }>

export type SettingsQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly email?: string | null
    readonly sms: string
  }
  readonly tikTokProfile?: {
    readonly __typename: 'TikTokProfile'
    readonly profileName?: string | null
  } | null
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly id: string
    readonly profileName: string
    readonly verified: boolean
  } | null
}

export type NotificationPreferencesQueryVariables = Exact<{
  [key: string]: never
}>

export type NotificationPreferencesQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly isWelcomeFlowCompleted: boolean
    readonly email?: string | null
    readonly emailVerified: boolean
    readonly sms: string
    readonly smsVerified: boolean
  }
  readonly notificationPreferences: {
    readonly __typename: 'UserNotificationPreferences'
    readonly id: string
    readonly smsGeneralNotifications: boolean
    readonly emailGeneralNotifications: boolean
    readonly pushGeneralNotifications: boolean
    readonly smsNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
    readonly emailNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
    readonly pushNotifications: {
      readonly __typename: 'UserNotificationPreferences_MediumGroupConfig'
      readonly PRODUCT_BOUGHT: boolean
      readonly BOUNTY_FEEDBACK: boolean
      readonly MONEY_AVAILABLE: boolean
      readonly POST_REMINDERS: boolean
      readonly SPARK_CODES: boolean
      readonly SPECIAL_OFFERS: boolean
      readonly SPECIAL_OFFERS_NETWORK: boolean
      readonly UPDATES_ABOUT_BOUNTY: boolean
    }
  }
}

export type VerifyEmailQueryVariables = Exact<{ [key: string]: never }>

export type VerifyEmailQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly emailVerified: boolean
    readonly email?: string | null
  }
}

export type VerifyInstagramQueryVariables = Exact<{ [key: string]: never }>

export type VerifyInstagramQuery = {
  readonly __typename: 'Query'
  readonly instagramProfile?: {
    readonly __typename: 'InstagramProfile'
    readonly id: string
    readonly profileName: string
    readonly displayName?: string | null
    readonly bioDescription?: string | null
    readonly verified: boolean
    readonly followerCount?: number | null
    readonly followingCount?: number | null
    readonly likesCount?: number | null
  } | null
}

export type WalletActivityLogQueryVariables = Exact<{ [key: string]: never }>

export type WalletActivityLogQuery = {
  readonly __typename: 'Query'
  readonly userPayments: ReadonlyArray<{
    readonly __typename: 'UserPayment'
    readonly id: string
    readonly value?: number | null
    readonly type: UserPayment_Type
    readonly status: UserPayment_Status
    readonly approvedAt?: any | null
    readonly settledAt?: any | null
    readonly deniedAt?: any | null
    readonly failedAt?: any | null
    readonly message: string
    readonly failureMessage?: string | null
    readonly createdAt: any
  }>
}

export type WalletBalanceCardQueryVariables = Exact<{ [key: string]: never }>

export type WalletBalanceCardQuery = {
  readonly __typename: 'Query'
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly pendingPayments: number
    readonly settledBalance: number
    readonly updatedAt?: any | null
  } | null
}

export type StripeCallbackQueryVariables = Exact<{ [key: string]: never }>

export type StripeCallbackQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountInfo: {
    readonly __typename: 'StripeAccountInfo'
    readonly accountId?: string | null
    readonly payoutsEnabled: boolean
    readonly accountExists: boolean
    readonly pendingBalance: number
    readonly totalBalance: number
    readonly last4?: string | null
  }
}

export type AccountLinkFailQueryVariables = Exact<{ [key: string]: never }>

export type AccountLinkFailQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountLoginLink: {
    readonly __typename: 'StripeAccountLoginLinkResult'
    readonly url?: string | null
  }
}

export type WithdrawNonUsCreatorPaymentQueryVariables = Exact<{
  [key: string]: never
}>

export type WithdrawNonUsCreatorPaymentQuery = {
  readonly __typename: 'Query'
  readonly currentUserProfile?: {
    readonly __typename: 'UserProfileInfo'
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly address1?: string | null
    readonly address2?: string | null
    readonly city?: string | null
    readonly zip?: string | null
    readonly state?: string | null
    readonly country?: string | null
  } | null
}

export type WalletNonUsCreatorQueryVariables = Exact<{ [key: string]: never }>

export type WalletNonUsCreatorQuery = {
  readonly __typename: 'Query'
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly settledBalance: number
    readonly pendingWithdrawals: number
    readonly updatedAt?: any | null
  } | null
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly emailVerified: boolean
  }
}

export type WalletWithdrawQueryVariables = Exact<{ [key: string]: never }>

export type WalletWithdrawQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountInfo: {
    readonly __typename: 'StripeAccountInfo'
    readonly accountId?: string | null
    readonly payoutsEnabled: boolean
    readonly accountExists: boolean
    readonly pendingBalance: number
    readonly totalBalance: number
    readonly last4?: string | null
  }
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly id: string
    readonly availableBalance: number
    readonly settledBalance: number
    readonly pendingWithdrawals: number
    readonly updatedAt?: any | null
  } | null
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: string
    readonly emailVerified: boolean
  }
}

export type BankAccountAlreadyLinkedQueryVariables = Exact<{
  [key: string]: never
}>

export type BankAccountAlreadyLinkedQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountInfo: {
    readonly __typename: 'StripeAccountInfo'
    readonly last4?: string | null
  }
  readonly stripeAccountLoginLink: {
    readonly __typename: 'StripeAccountLoginLinkResult'
    readonly url?: string | null
  }
}

export type LinkStripeBankAccountQueryVariables = Exact<{
  [key: string]: never
}>

export type LinkStripeBankAccountQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountInfo: {
    readonly __typename: 'StripeAccountInfo'
    readonly accountExists: boolean
  }
}

export type LinkStripeBankAccountLoginLinkQueryVariables = Exact<{
  [key: string]: never
}>

export type LinkStripeBankAccountLoginLinkQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountLoginLink: {
    readonly __typename: 'StripeAccountLoginLinkResult'
    readonly url?: string | null
  }
}

export type WalletWithdrawFormQueryVariables = Exact<{ [key: string]: never }>

export type WalletWithdrawFormQuery = {
  readonly __typename: 'Query'
  readonly stripeAccountInfo: {
    readonly __typename: 'StripeAccountInfo'
    readonly payoutsEnabled: boolean
  }
  readonly userPaymentSummary?: {
    readonly __typename: 'UserPaymentSummary'
    readonly availableBalance: number
  } | null
}

export const BountyOverviewPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BountyOverviewParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserBounty' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgLink' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImgLink' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentTypesAllowed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentTypeSubmitted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableToAcceptBounty' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationCode' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'briefId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brief' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSnapshot' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPayoutInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumPayoutAmount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BountyOverviewPartsFragment, unknown>
export const BountyPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BountyParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserBounty' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liveAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'liveEndsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentTypesAllowed' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentTypeSubmitted' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoDescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sparkAdCodeRequests' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestLog' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'showOver21AgeGate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userBountyId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shareCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedbackMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deniedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failureMessage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimatedFundsAvailableDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfilledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inTransitAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelledAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgLink' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productInstruction' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headline' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImgLink' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tikTokUsername' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instagramUsername' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandedHashtag' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailLocations' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailLocatorUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailBrief' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headline' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isNewPayoutStructure' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableToAcceptBounty' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationCode' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extensionInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableToExtendBounty' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableToExtendDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumPayoutInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isEligibleForMinimumPayout' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumPayoutAmount' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'surveyAnswers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bountyDefaultBrief' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentSnapshot' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minBountyPayment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productGiftConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasMinPayment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payPerViews' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noPayment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'briefId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brief' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSnapshot' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'canPostToOtherSocials' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BountyPartsFragment, unknown>
export const MessagePartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MessageParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSystem' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChatMessageMetaGiftOffer' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftValue' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerStatus' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ChatMessageMetaSparkCode' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bountyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sparkCodeAmount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sparkCodeStatus' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessagePartsFragment, unknown>
export const ConversationPartsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversationParts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Conversation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastReceivedActionableMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystem' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestMessage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSystem' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unreadCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConversationPartsFragment, unknown>
export const SubmitPayoutRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitPayoutRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserPayoutRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPayoutRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitPayoutRequestMutation,
  SubmitPayoutRequestMutationVariables
>
export const SubmitPayoutRequestStripeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitPayoutRequestStripe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserPayoutRequestStripeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPayoutRequestStripe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitPayoutRequestStripeMutation,
  SubmitPayoutRequestStripeMutationVariables
>
export const RequestExtensionForBountyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestExtensionForBounty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestExtensionForBounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestExtensionForBountyMutation,
  RequestExtensionForBountyMutationVariables
>
export const ForceRefreshPaymentSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForceRefreshPaymentSummary' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forceRefreshPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withdrawnBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingPayments' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalEarned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingWithdrawals' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastPayoutDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastWithdrawalDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForceRefreshPaymentSummaryMutation,
  ForceRefreshPaymentSummaryMutationVariables
>
export const StartStripeAccountLinkingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartStripeAccountLinking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'countryCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startStripeAccountLinking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'countryCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartStripeAccountLinkingMutation,
  StartStripeAccountLinkingMutationVariables
>
export const CreateUserProfileInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserProfileInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserProfileInfo' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserProfileInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserProfileInfoMutation,
  CreateUserProfileInfoMutationVariables
>
export const UpdateUserProfileInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserProfileInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserProfileInfo' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserProfileInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserProfileInfoMutation,
  UpdateUserProfileInfoMutationVariables
>
export const AddPushTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPushToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPushToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPushTokenMutation,
  AddPushTokenMutationVariables
>
export const RequestEmailVerificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestEmailVerification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestEmailVerification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>
export const SubmitEmailVerificationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitEmailVerificationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitEmailVerificationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitEmailVerificationCodeMutation,
  SubmitEmailVerificationCodeMutationVariables
>
export const AcceptBountyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptBounty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyContentType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserBountyContentType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'briefId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptBounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyContentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyContentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'briefId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'briefId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'source' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AcceptBountyMutation,
  AcceptBountyMutationVariables
>
export const ClaimGiftOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimGiftOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimGiftOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClaimGiftOfferMutation,
  ClaimGiftOfferMutationVariables
>
export const RedeemGiftOfferProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemGiftOfferProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RedeemGiftOfferProductInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemGiftOfferProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RedeemGiftOfferProductMutation,
  RedeemGiftOfferProductMutationVariables
>
export const RedeemGiftOfferBountyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemGiftOfferBounty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RedeemGiftOfferBountyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemGiftOfferBounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RedeemGiftOfferBountyMutation,
  RedeemGiftOfferBountyMutationVariables
>
export const UpdateNotificationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNotificationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNotificationPrefInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationPreference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationPreferencesMutation,
  UpdateNotificationPreferencesMutationVariables
>
export const AcceptSparkAdCodeRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptSparkAdCodeRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptSparkAdCodeRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'requestId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptSparkAdCodeRequestMutation,
  AcceptSparkAdCodeRequestMutationVariables
>
export const MarkBountyOrderDeliveredDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkBountyOrderDelivered' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markBountyOrderDelivered' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MarkBountyOrderDeliveredMutation,
  MarkBountyOrderDeliveredMutationVariables
>
export const ForceCheckForNewVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForceCheckForNewVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forceCheckForNewVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForceCheckForNewVideoMutation,
  ForceCheckForNewVideoMutationVariables
>
export const CompleteWelcomeFlowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteWelcomeFlow' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeWelcomeFlow' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWelcomeFlowCompleted' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteWelcomeFlowMutation,
  CompleteWelcomeFlowMutationVariables
>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const CheckInstagramProfileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckInstagramProfileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkInstagramProfileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bioDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followerCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'followingCount' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckInstagramProfileNameMutation,
  CheckInstagramProfileNameMutationVariables
>
export const ConfirmInstagramProfileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmInstagramProfileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmInstagramProfileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verified' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmInstagramProfileNameMutation,
  ConfirmInstagramProfileNameMutationVariables
>
export const CreateCrossPostBountyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCrossPostBounty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyContentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserBountyContentType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCrossPostBounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bountyContentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyContentType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCrossPostBountyMutation,
  CreateCrossPostBountyMutationVariables
>
export const SubmitUserMediaFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitUserMediaFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userMediaFeedbackInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserMediaFeedbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitUserMediaFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userMediaFeedbackInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userMediaFeedbackInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'experimentId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fileLink' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitUserMediaFeedbackMutation,
  SubmitUserMediaFeedbackMutationVariables
>
export const TermsAcceptedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TermsAccepted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'termName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAccepted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'termName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'termName' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TermsAcceptedMutation,
  TermsAcceptedMutationVariables
>
export const SelectableBriefsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectableBriefs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectableBriefs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSnapshot' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectableBriefsQuery,
  SelectableBriefsQueryVariables
>
export const RequireUpdatedTermsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequireUpdatedTerms' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWelcomeFlowCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedLatestTerms' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequireUpdatedTermsQuery,
  RequireUpdatedTermsQueryVariables
>
export const RequireVerifiedInstagramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequireVerifiedInstagram' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequireVerifiedInstagramQuery,
  RequireVerifiedInstagramQueryVariables
>
export const VerifyTikTokAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyTikTokAuth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'verifyTikTokAuth' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyTikTokAuthMutation,
  VerifyTikTokAuthMutationVariables
>
export const RequireWelcomeFlowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequireWelcomeFlow' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWelcomeFlowCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequireWelcomeFlowQuery,
  RequireWelcomeFlowQueryVariables
>
export const BountyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserBountyInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'take' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BountyOverviewParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyOverviewPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<BountyListQuery, BountyListQueryVariables>
export const BountyAcceptBriefDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptBrief' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptBriefQuery,
  BountyAcceptBriefQueryVariables
>
export const BountyAcceptContentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptContentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptContentTypeQuery,
  BountyAcceptContentTypeQueryVariables
>
export const BountyAcceptGuidelineDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptGuideline' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptGuidelineQuery,
  BountyAcceptGuidelineQueryVariables
>
export const BountyAcceptRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptRulesQuery,
  BountyAcceptRulesQueryVariables
>
export const BountyAcceptSelectBriefDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptSelectBrief' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptSelectBriefQuery,
  BountyAcceptSelectBriefQueryVariables
>
export const BountyAcceptSurveyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyAcceptSurvey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BountyAcceptSurveyQuery,
  BountyAcceptSurveyQueryVariables
>
export const UpdateSurveyAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSurveyAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'surveyAnswers' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRetailSurveyAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userBountyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'surveyAnswers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'surveyAnswers' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateSurveyAnswersMutation,
  UpdateSurveyAnswersMutationVariables
>
export const BountyDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BountyDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bountyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'profileLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bountyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyParts' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<BountyDetailQuery, BountyDetailQueryVariables>
export const JoinCommunityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'JoinCommunity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinCommunity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bounty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JoinCommunityMutation,
  JoinCommunityMutationVariables
>
export const ConversationDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConversationDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'lastReceivedActionableMessage',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSystem' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sentAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isSystem' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sentAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'skip' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skip' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'take' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'take' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MessageParts' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MessagePartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ConversationDetailQuery,
  ConversationDetailQueryVariables
>
export const ConversationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Conversations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConversationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConversationParts' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'skip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'take' } },
              ],
            },
          },
        ],
      },
    },
    ...ConversationPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ConversationsQuery, ConversationsQueryVariables>
export const GiftRedeemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GiftRedeem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferProduct' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentSnapshot' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minBountyPayment' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productGiftConfig',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasMinPayment',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'payPerViews',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'noPayment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferBounty' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minBountyPayment' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftOfferDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferBountyDetails' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minBountyPayment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferProductDetails' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storeName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GiftRedeemQuery, GiftRedeemQueryVariables>
export const GiftSuccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GiftSuccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWelcomeFlowCompleted' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferProduct' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferBounty' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minBountyPayment' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GiftSuccessQuery, GiftSuccessQueryVariables>
export const GiftWelcomeAuthedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GiftWelcomeAuthed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferProduct' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferBounty' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minBountyPayment' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GiftWelcomeAuthedQuery,
  GiftWelcomeAuthedQueryVariables
>
export const UseClaimGiftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseClaimGift' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'giftOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferProduct' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferBounty' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minBountyPayment' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeLogo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'GiftOfferError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseClaimGiftQuery, UseClaimGiftQueryVariables>
export const HomeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Home' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'params' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserBountyInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bounties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'params' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logoImgLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'communities' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedBounties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BountyOverviewParts' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingPayments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featured' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minBountyPayment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startingCPM' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startingCPMViews' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImgLinkPrimary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headerImgLinkSecondary' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followerCount' },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyOverviewPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<HomeQuery, HomeQueryVariables>
export const AuthTikTokDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthTikTok' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followerCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthTikTokQuery, AuthTikTokQueryVariables>
export const ConnectSocialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConnectSocials' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectSocialsQuery, ConnectSocialsQueryVariables>
export const CurrentRewardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentRewards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingPayments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentRewardsQuery, CurrentRewardsQueryVariables>
export const CreateRetailBountyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRetailBounty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRetailBounty' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'storeId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'storeId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserBounty' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BountyParts' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateRetailBountyError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bountyId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BountyPartsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateRetailBountyMutation,
  CreateRetailBountyMutationVariables
>
export const ManageAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingPayments' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageAccountQuery, ManageAccountQueryVariables>
export const ManageAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManageAddresses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageAddressesQuery,
  ManageAddressesQueryVariables
>
export const SettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Settings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sms' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tikTokProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsQuery, SettingsQueryVariables>
export const NotificationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isWelcomeFlowCompleted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'smsVerified' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushGeneralNotifications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'smsNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushNotifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PRODUCT_BOUGHT' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BOUNTY_FEEDBACK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'MONEY_AVAILABLE' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'POST_REMINDERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPARK_CODES' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SPECIAL_OFFERS_NETWORK' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UPDATES_ABOUT_BOUNTY' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationPreferencesQuery,
  NotificationPreferencesQueryVariables
>
export const VerifyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerifyEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailQuery, VerifyEmailQueryVariables>
export const VerifyInstagramDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerifyInstagram' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instagramProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bioDescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'verified' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followerCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followingCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyInstagramQuery,
  VerifyInstagramQueryVariables
>
export const WalletActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WalletActivityLog' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPayments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'settledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deniedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failureMessage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WalletActivityLogQuery,
  WalletActivityLogQueryVariables
>
export const WalletBalanceCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WalletBalanceCard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingPayments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WalletBalanceCardQuery,
  WalletBalanceCardQueryVariables
>
export const StripeCallbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StripeCallback' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payoutsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountExists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBalance' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StripeCallbackQuery, StripeCallbackQueryVariables>
export const AccountLinkFailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountLinkFail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountLoginLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountLinkFailQuery,
  AccountLinkFailQueryVariables
>
export const WithdrawNonUsCreatorPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WithdrawNonUsCreatorPayment' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WithdrawNonUsCreatorPaymentQuery,
  WithdrawNonUsCreatorPaymentQueryVariables
>
export const WalletNonUsCreatorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WalletNonUsCreator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingWithdrawals' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WalletNonUsCreatorQuery,
  WalletNonUsCreatorQueryVariables
>
export const WalletWithdrawDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WalletWithdraw' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payoutsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountExists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBalance' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settledBalance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingWithdrawals' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailVerified' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WalletWithdrawQuery, WalletWithdrawQueryVariables>
export const BankAccountAlreadyLinkedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BankAccountAlreadyLinked' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountLoginLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BankAccountAlreadyLinkedQuery,
  BankAccountAlreadyLinkedQueryVariables
>
export const LinkStripeBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LinkStripeBankAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountExists' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LinkStripeBankAccountQuery,
  LinkStripeBankAccountQueryVariables
>
export const LinkStripeBankAccountLoginLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LinkStripeBankAccountLoginLink' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountLoginLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LinkStripeBankAccountLoginLinkQuery,
  LinkStripeBankAccountLoginLinkQueryVariables
>
export const WalletWithdrawFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WalletWithdrawForm' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeAccountInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payoutsEnabled' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WalletWithdrawFormQuery,
  WalletWithdrawFormQueryVariables
>

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitPayoutRequestMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { submitPayoutRequest }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSubmitPayoutRequestMutation = (
  resolver: GraphQLResponseResolver<
    SubmitPayoutRequestMutation,
    SubmitPayoutRequestMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    SubmitPayoutRequestMutation,
    SubmitPayoutRequestMutationVariables
  >('SubmitPayoutRequest', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitPayoutRequestStripeMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { submitPayoutRequestStripe }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSubmitPayoutRequestStripeMutation = (
  resolver: GraphQLResponseResolver<
    SubmitPayoutRequestStripeMutation,
    SubmitPayoutRequestStripeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    SubmitPayoutRequestStripeMutation,
    SubmitPayoutRequestStripeMutationVariables
  >('SubmitPayoutRequestStripe', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestExtensionForBountyMutation(
 *   ({ query, variables }) => {
 *     const { bountyId } = variables;
 *     return HttpResponse.json({
 *       data: { requestExtensionForBounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRequestExtensionForBountyMutation = (
  resolver: GraphQLResponseResolver<
    RequestExtensionForBountyMutation,
    RequestExtensionForBountyMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    RequestExtensionForBountyMutation,
    RequestExtensionForBountyMutationVariables
  >('RequestExtensionForBounty', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockForceRefreshPaymentSummaryMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { forceRefreshPaymentSummary }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockForceRefreshPaymentSummaryMutation = (
  resolver: GraphQLResponseResolver<
    ForceRefreshPaymentSummaryMutation,
    ForceRefreshPaymentSummaryMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    ForceRefreshPaymentSummaryMutation,
    ForceRefreshPaymentSummaryMutationVariables
  >('ForceRefreshPaymentSummary', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStartStripeAccountLinkingMutation(
 *   ({ query, variables }) => {
 *     const { countryCode } = variables;
 *     return HttpResponse.json({
 *       data: { startStripeAccountLinking }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStartStripeAccountLinkingMutation = (
  resolver: GraphQLResponseResolver<
    StartStripeAccountLinkingMutation,
    StartStripeAccountLinkingMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    StartStripeAccountLinkingMutation,
    StartStripeAccountLinkingMutationVariables
  >('StartStripeAccountLinking', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateUserProfileInfoMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { createUserProfileInfo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateUserProfileInfoMutation = (
  resolver: GraphQLResponseResolver<
    CreateUserProfileInfoMutation,
    CreateUserProfileInfoMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    CreateUserProfileInfoMutation,
    CreateUserProfileInfoMutationVariables
  >('CreateUserProfileInfo', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateUserProfileInfoMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { updateUserProfileInfo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateUserProfileInfoMutation = (
  resolver: GraphQLResponseResolver<
    UpdateUserProfileInfoMutation,
    UpdateUserProfileInfoMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    UpdateUserProfileInfoMutation,
    UpdateUserProfileInfoMutationVariables
  >('UpdateUserProfileInfo', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddPushTokenMutation(
 *   ({ query, variables }) => {
 *     const { token } = variables;
 *     return HttpResponse.json({
 *       data: { addPushToken }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddPushTokenMutation = (
  resolver: GraphQLResponseResolver<
    AddPushTokenMutation,
    AddPushTokenMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<AddPushTokenMutation, AddPushTokenMutationVariables>(
    'AddPushToken',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestEmailVerificationMutation(
 *   ({ query, variables }) => {
 *     const { email } = variables;
 *     return HttpResponse.json({
 *       data: { requestEmailVerification }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRequestEmailVerificationMutation = (
  resolver: GraphQLResponseResolver<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >('RequestEmailVerification', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitEmailVerificationCodeMutation(
 *   ({ query, variables }) => {
 *     const { code } = variables;
 *     return HttpResponse.json({
 *       data: { submitEmailVerificationCode }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSubmitEmailVerificationCodeMutation = (
  resolver: GraphQLResponseResolver<
    SubmitEmailVerificationCodeMutation,
    SubmitEmailVerificationCodeMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    SubmitEmailVerificationCodeMutation,
    SubmitEmailVerificationCodeMutationVariables
  >('SubmitEmailVerificationCode', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAcceptBountyMutation(
 *   ({ query, variables }) => {
 *     const { bountyId, bountyContentType, briefId, source } = variables;
 *     return HttpResponse.json({
 *       data: { acceptBounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAcceptBountyMutation = (
  resolver: GraphQLResponseResolver<
    AcceptBountyMutation,
    AcceptBountyMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<AcceptBountyMutation, AcceptBountyMutationVariables>(
    'AcceptBounty',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockClaimGiftOfferMutation(
 *   ({ query, variables }) => {
 *     const { key } = variables;
 *     return HttpResponse.json({
 *       data: { claimGiftOffer }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockClaimGiftOfferMutation = (
  resolver: GraphQLResponseResolver<
    ClaimGiftOfferMutation,
    ClaimGiftOfferMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<ClaimGiftOfferMutation, ClaimGiftOfferMutationVariables>(
    'ClaimGiftOffer',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRedeemGiftOfferProductMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { redeemGiftOfferProduct }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRedeemGiftOfferProductMutation = (
  resolver: GraphQLResponseResolver<
    RedeemGiftOfferProductMutation,
    RedeemGiftOfferProductMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    RedeemGiftOfferProductMutation,
    RedeemGiftOfferProductMutationVariables
  >('RedeemGiftOfferProduct', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRedeemGiftOfferBountyMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { redeemGiftOfferBounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRedeemGiftOfferBountyMutation = (
  resolver: GraphQLResponseResolver<
    RedeemGiftOfferBountyMutation,
    RedeemGiftOfferBountyMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    RedeemGiftOfferBountyMutation,
    RedeemGiftOfferBountyMutationVariables
  >('RedeemGiftOfferBounty', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateNotificationPreferencesMutation(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { updateNotificationPreference }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateNotificationPreferencesMutation = (
  resolver: GraphQLResponseResolver<
    UpdateNotificationPreferencesMutation,
    UpdateNotificationPreferencesMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    UpdateNotificationPreferencesMutation,
    UpdateNotificationPreferencesMutationVariables
  >('UpdateNotificationPreferences', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAcceptSparkAdCodeRequestMutation(
 *   ({ query, variables }) => {
 *     const { code, requestId } = variables;
 *     return HttpResponse.json({
 *       data: { acceptSparkAdCodeRequest }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAcceptSparkAdCodeRequestMutation = (
  resolver: GraphQLResponseResolver<
    AcceptSparkAdCodeRequestMutation,
    AcceptSparkAdCodeRequestMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    AcceptSparkAdCodeRequestMutation,
    AcceptSparkAdCodeRequestMutationVariables
  >('AcceptSparkAdCodeRequest', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMarkBountyOrderDeliveredMutation(
 *   ({ query, variables }) => {
 *     const { bountyId } = variables;
 *     return HttpResponse.json({
 *       data: { markBountyOrderDelivered }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMarkBountyOrderDeliveredMutation = (
  resolver: GraphQLResponseResolver<
    MarkBountyOrderDeliveredMutation,
    MarkBountyOrderDeliveredMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    MarkBountyOrderDeliveredMutation,
    MarkBountyOrderDeliveredMutationVariables
  >('MarkBountyOrderDelivered', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockForceCheckForNewVideoMutation(
 *   ({ query, variables }) => {
 *     const { bountyId } = variables;
 *     return HttpResponse.json({
 *       data: { forceCheckForNewVideo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockForceCheckForNewVideoMutation = (
  resolver: GraphQLResponseResolver<
    ForceCheckForNewVideoMutation,
    ForceCheckForNewVideoMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    ForceCheckForNewVideoMutation,
    ForceCheckForNewVideoMutationVariables
  >('ForceCheckForNewVideo', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCompleteWelcomeFlowMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { completeWelcomeFlow }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCompleteWelcomeFlowMutation = (
  resolver: GraphQLResponseResolver<
    CompleteWelcomeFlowMutation,
    CompleteWelcomeFlowMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    CompleteWelcomeFlowMutation,
    CompleteWelcomeFlowMutationVariables
  >('CompleteWelcomeFlow', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteUserMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { deleteUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteUserMutation = (
  resolver: GraphQLResponseResolver<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteUserMutation, DeleteUserMutationVariables>(
    'DeleteUser',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCheckInstagramProfileNameMutation(
 *   ({ query, variables }) => {
 *     const { profileName } = variables;
 *     return HttpResponse.json({
 *       data: { checkInstagramProfileName }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCheckInstagramProfileNameMutation = (
  resolver: GraphQLResponseResolver<
    CheckInstagramProfileNameMutation,
    CheckInstagramProfileNameMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    CheckInstagramProfileNameMutation,
    CheckInstagramProfileNameMutationVariables
  >('CheckInstagramProfileName', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockConfirmInstagramProfileNameMutation(
 *   ({ query, variables }) => {
 *     const { profileName } = variables;
 *     return HttpResponse.json({
 *       data: { confirmInstagramProfileName }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockConfirmInstagramProfileNameMutation = (
  resolver: GraphQLResponseResolver<
    ConfirmInstagramProfileNameMutation,
    ConfirmInstagramProfileNameMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    ConfirmInstagramProfileNameMutation,
    ConfirmInstagramProfileNameMutationVariables
  >('ConfirmInstagramProfileName', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateCrossPostBountyMutation(
 *   ({ query, variables }) => {
 *     const { bountyId, bountyContentType } = variables;
 *     return HttpResponse.json({
 *       data: { createCrossPostBounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateCrossPostBountyMutation = (
  resolver: GraphQLResponseResolver<
    CreateCrossPostBountyMutation,
    CreateCrossPostBountyMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    CreateCrossPostBountyMutation,
    CreateCrossPostBountyMutationVariables
  >('CreateCrossPostBounty', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitUserMediaFeedbackMutation(
 *   ({ query, variables }) => {
 *     const { userMediaFeedbackInput } = variables;
 *     return HttpResponse.json({
 *       data: { submitUserMediaFeedback }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSubmitUserMediaFeedbackMutation = (
  resolver: GraphQLResponseResolver<
    SubmitUserMediaFeedbackMutation,
    SubmitUserMediaFeedbackMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    SubmitUserMediaFeedbackMutation,
    SubmitUserMediaFeedbackMutationVariables
  >('SubmitUserMediaFeedback', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTermsAcceptedMutation(
 *   ({ query, variables }) => {
 *     const { termName } = variables;
 *     return HttpResponse.json({
 *       data: { termsAccepted }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockTermsAcceptedMutation = (
  resolver: GraphQLResponseResolver<
    TermsAcceptedMutation,
    TermsAcceptedMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<TermsAcceptedMutation, TermsAcceptedMutationVariables>(
    'TermsAccepted',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSelectableBriefsQuery(
 *   ({ query, variables }) => {
 *     const { storeId } = variables;
 *     return HttpResponse.json({
 *       data: { selectableBriefs }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSelectableBriefsQuery = (
  resolver: GraphQLResponseResolver<
    SelectableBriefsQuery,
    SelectableBriefsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<SelectableBriefsQuery, SelectableBriefsQueryVariables>(
    'SelectableBriefs',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequireUpdatedTermsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRequireUpdatedTermsQuery = (
  resolver: GraphQLResponseResolver<
    RequireUpdatedTermsQuery,
    RequireUpdatedTermsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<RequireUpdatedTermsQuery, RequireUpdatedTermsQueryVariables>(
    'RequireUpdatedTerms',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequireVerifiedInstagramQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRequireVerifiedInstagramQuery = (
  resolver: GraphQLResponseResolver<
    RequireVerifiedInstagramQuery,
    RequireVerifiedInstagramQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    RequireVerifiedInstagramQuery,
    RequireVerifiedInstagramQueryVariables
  >('RequireVerifiedInstagram', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyTikTokAuthMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { verifyTikTokAuth }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockVerifyTikTokAuthMutation = (
  resolver: GraphQLResponseResolver<
    VerifyTikTokAuthMutation,
    VerifyTikTokAuthMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<VerifyTikTokAuthMutation, VerifyTikTokAuthMutationVariables>(
    'VerifyTikTokAuth',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequireWelcomeFlowQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { tikTokProfile, currentUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRequireWelcomeFlowQuery = (
  resolver: GraphQLResponseResolver<
    RequireWelcomeFlowQuery,
    RequireWelcomeFlowQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<RequireWelcomeFlowQuery, RequireWelcomeFlowQueryVariables>(
    'RequireWelcomeFlow',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyListQuery(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { bounties }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyListQuery = (
  resolver: GraphQLResponseResolver<BountyListQuery, BountyListQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BountyListQuery, BountyListQueryVariables>(
    'BountyList',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptBriefQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, bounty, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptBriefQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptBriefQuery,
    BountyAcceptBriefQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BountyAcceptBriefQuery, BountyAcceptBriefQueryVariables>(
    'BountyAcceptBrief',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptContentTypeQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, bounty, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptContentTypeQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptContentTypeQuery,
    BountyAcceptContentTypeQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    BountyAcceptContentTypeQuery,
    BountyAcceptContentTypeQueryVariables
  >('BountyAcceptContentType', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptGuidelineQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, bounty, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptGuidelineQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptGuidelineQuery,
    BountyAcceptGuidelineQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    BountyAcceptGuidelineQuery,
    BountyAcceptGuidelineQueryVariables
  >('BountyAcceptGuideline', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptRulesQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, bounty, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptRulesQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptRulesQuery,
    BountyAcceptRulesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BountyAcceptRulesQuery, BountyAcceptRulesQueryVariables>(
    'BountyAcceptRules',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptSelectBriefQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, bounty, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptSelectBriefQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptSelectBriefQuery,
    BountyAcceptSelectBriefQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    BountyAcceptSelectBriefQuery,
    BountyAcceptSelectBriefQueryVariables
  >('BountyAcceptSelectBrief', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyAcceptSurveyQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { bounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyAcceptSurveyQuery = (
  resolver: GraphQLResponseResolver<
    BountyAcceptSurveyQuery,
    BountyAcceptSurveyQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BountyAcceptSurveyQuery, BountyAcceptSurveyQueryVariables>(
    'BountyAcceptSurvey',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateSurveyAnswersMutation(
 *   ({ query, variables }) => {
 *     const { id, surveyAnswers } = variables;
 *     return HttpResponse.json({
 *       data: { updateRetailSurveyAnswers }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateSurveyAnswersMutation = (
  resolver: GraphQLResponseResolver<
    UpdateSurveyAnswersMutation,
    UpdateSurveyAnswersMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    UpdateSurveyAnswersMutation,
    UpdateSurveyAnswersMutationVariables
  >('UpdateSurveyAnswers', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBountyDetailQuery(
 *   ({ query, variables }) => {
 *     const { bountyId } = variables;
 *     return HttpResponse.json({
 *       data: { tikTokProfile, instagramProfile, bounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBountyDetailQuery = (
  resolver: GraphQLResponseResolver<
    BountyDetailQuery,
    BountyDetailQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<BountyDetailQuery, BountyDetailQueryVariables>(
    'BountyDetail',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockJoinCommunityMutation(
 *   ({ query, variables }) => {
 *     const { storeId } = variables;
 *     return HttpResponse.json({
 *       data: { joinCommunity }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockJoinCommunityMutation = (
  resolver: GraphQLResponseResolver<
    JoinCommunityMutation,
    JoinCommunityMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<JoinCommunityMutation, JoinCommunityMutationVariables>(
    'JoinCommunity',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockConversationDetailQuery(
 *   ({ query, variables }) => {
 *     const { id, skip, take } = variables;
 *     return HttpResponse.json({
 *       data: { conversation }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockConversationDetailQuery = (
  resolver: GraphQLResponseResolver<
    ConversationDetailQuery,
    ConversationDetailQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ConversationDetailQuery, ConversationDetailQueryVariables>(
    'ConversationDetail',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockConversationsQuery(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { conversations }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockConversationsQuery = (
  resolver: GraphQLResponseResolver<
    ConversationsQuery,
    ConversationsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ConversationsQuery, ConversationsQueryVariables>(
    'Conversations',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGiftRedeemQuery(
 *   ({ query, variables }) => {
 *     const { key } = variables;
 *     return HttpResponse.json({
 *       data: { currentUserProfile, giftOffer, giftOfferDetails }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGiftRedeemQuery = (
  resolver: GraphQLResponseResolver<GiftRedeemQuery, GiftRedeemQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GiftRedeemQuery, GiftRedeemQueryVariables>(
    'GiftRedeem',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGiftSuccessQuery(
 *   ({ query, variables }) => {
 *     const { key } = variables;
 *     return HttpResponse.json({
 *       data: { currentUser, giftOffer }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGiftSuccessQuery = (
  resolver: GraphQLResponseResolver<
    GiftSuccessQuery,
    GiftSuccessQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GiftSuccessQuery, GiftSuccessQueryVariables>(
    'GiftSuccess',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGiftWelcomeAuthedQuery(
 *   ({ query, variables }) => {
 *     const { key } = variables;
 *     return HttpResponse.json({
 *       data: { giftOffer }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGiftWelcomeAuthedQuery = (
  resolver: GraphQLResponseResolver<
    GiftWelcomeAuthedQuery,
    GiftWelcomeAuthedQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<GiftWelcomeAuthedQuery, GiftWelcomeAuthedQueryVariables>(
    'GiftWelcomeAuthed',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUseClaimGiftQuery(
 *   ({ query, variables }) => {
 *     const { key } = variables;
 *     return HttpResponse.json({
 *       data: { giftOffer }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUseClaimGiftQuery = (
  resolver: GraphQLResponseResolver<
    UseClaimGiftQuery,
    UseClaimGiftQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<UseClaimGiftQuery, UseClaimGiftQueryVariables>(
    'UseClaimGift',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockHomeQuery(
 *   ({ query, variables }) => {
 *     const { params } = variables;
 *     return HttpResponse.json({
 *       data: { bounties, currentUser, recommendedBounties, userPaymentSummary, recommendedStores, tikTokProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockHomeQuery = (
  resolver: GraphQLResponseResolver<HomeQuery, HomeQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<HomeQuery, HomeQueryVariables>('Home', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAuthTikTokQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { tikTokProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAuthTikTokQuery = (
  resolver: GraphQLResponseResolver<AuthTikTokQuery, AuthTikTokQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<AuthTikTokQuery, AuthTikTokQueryVariables>(
    'AuthTikTok',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockConnectSocialsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { tikTokProfile, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockConnectSocialsQuery = (
  resolver: GraphQLResponseResolver<
    ConnectSocialsQuery,
    ConnectSocialsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ConnectSocialsQuery, ConnectSocialsQueryVariables>(
    'ConnectSocials',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCurrentRewardsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { userPaymentSummary, tikTokProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCurrentRewardsQuery = (
  resolver: GraphQLResponseResolver<
    CurrentRewardsQuery,
    CurrentRewardsQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<CurrentRewardsQuery, CurrentRewardsQueryVariables>(
    'CurrentRewards',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateRetailBountyMutation(
 *   ({ query, variables }) => {
 *     const { storeId } = variables;
 *     return HttpResponse.json({
 *       data: { createRetailBounty }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateRetailBountyMutation = (
  resolver: GraphQLResponseResolver<
    CreateRetailBountyMutation,
    CreateRetailBountyMutationVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<
    CreateRetailBountyMutation,
    CreateRetailBountyMutationVariables
  >('CreateRetailBounty', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockManageAccountQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { userPaymentSummary }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockManageAccountQuery = (
  resolver: GraphQLResponseResolver<
    ManageAccountQuery,
    ManageAccountQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ManageAccountQuery, ManageAccountQueryVariables>(
    'ManageAccount',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockManageAddressesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUserProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockManageAddressesQuery = (
  resolver: GraphQLResponseResolver<
    ManageAddressesQuery,
    ManageAddressesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<ManageAddressesQuery, ManageAddressesQueryVariables>(
    'ManageAddresses',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSettingsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUser, tikTokProfile, instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSettingsQuery = (
  resolver: GraphQLResponseResolver<SettingsQuery, SettingsQueryVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.query<SettingsQuery, SettingsQueryVariables>(
    'Settings',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockNotificationPreferencesQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUser, notificationPreferences }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockNotificationPreferencesQuery = (
  resolver: GraphQLResponseResolver<
    NotificationPreferencesQuery,
    NotificationPreferencesQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    NotificationPreferencesQuery,
    NotificationPreferencesQueryVariables
  >('NotificationPreferences', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyEmailQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockVerifyEmailQuery = (
  resolver: GraphQLResponseResolver<
    VerifyEmailQuery,
    VerifyEmailQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<VerifyEmailQuery, VerifyEmailQueryVariables>(
    'VerifyEmail',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyInstagramQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { instagramProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockVerifyInstagramQuery = (
  resolver: GraphQLResponseResolver<
    VerifyInstagramQuery,
    VerifyInstagramQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<VerifyInstagramQuery, VerifyInstagramQueryVariables>(
    'VerifyInstagram',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWalletActivityLogQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { userPayments }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWalletActivityLogQuery = (
  resolver: GraphQLResponseResolver<
    WalletActivityLogQuery,
    WalletActivityLogQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<WalletActivityLogQuery, WalletActivityLogQueryVariables>(
    'WalletActivityLog',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWalletBalanceCardQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { userPaymentSummary }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWalletBalanceCardQuery = (
  resolver: GraphQLResponseResolver<
    WalletBalanceCardQuery,
    WalletBalanceCardQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<WalletBalanceCardQuery, WalletBalanceCardQueryVariables>(
    'WalletBalanceCard',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockStripeCallbackQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountInfo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockStripeCallbackQuery = (
  resolver: GraphQLResponseResolver<
    StripeCallbackQuery,
    StripeCallbackQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<StripeCallbackQuery, StripeCallbackQueryVariables>(
    'StripeCallback',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAccountLinkFailQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountLoginLink }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAccountLinkFailQuery = (
  resolver: GraphQLResponseResolver<
    AccountLinkFailQuery,
    AccountLinkFailQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<AccountLinkFailQuery, AccountLinkFailQueryVariables>(
    'AccountLinkFail',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWithdrawNonUsCreatorPaymentQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { currentUserProfile }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWithdrawNonUsCreatorPaymentQuery = (
  resolver: GraphQLResponseResolver<
    WithdrawNonUsCreatorPaymentQuery,
    WithdrawNonUsCreatorPaymentQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    WithdrawNonUsCreatorPaymentQuery,
    WithdrawNonUsCreatorPaymentQueryVariables
  >('WithdrawNonUsCreatorPayment', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWalletNonUsCreatorQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { userPaymentSummary, currentUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWalletNonUsCreatorQuery = (
  resolver: GraphQLResponseResolver<
    WalletNonUsCreatorQuery,
    WalletNonUsCreatorQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<WalletNonUsCreatorQuery, WalletNonUsCreatorQueryVariables>(
    'WalletNonUsCreator',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWalletWithdrawQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountInfo, userPaymentSummary, currentUser }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWalletWithdrawQuery = (
  resolver: GraphQLResponseResolver<
    WalletWithdrawQuery,
    WalletWithdrawQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<WalletWithdrawQuery, WalletWithdrawQueryVariables>(
    'WalletWithdraw',
    resolver,
    options,
  )

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBankAccountAlreadyLinkedQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountInfo, stripeAccountLoginLink }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockBankAccountAlreadyLinkedQuery = (
  resolver: GraphQLResponseResolver<
    BankAccountAlreadyLinkedQuery,
    BankAccountAlreadyLinkedQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    BankAccountAlreadyLinkedQuery,
    BankAccountAlreadyLinkedQueryVariables
  >('BankAccountAlreadyLinked', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLinkStripeBankAccountQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountInfo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLinkStripeBankAccountQuery = (
  resolver: GraphQLResponseResolver<
    LinkStripeBankAccountQuery,
    LinkStripeBankAccountQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    LinkStripeBankAccountQuery,
    LinkStripeBankAccountQueryVariables
  >('LinkStripeBankAccount', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLinkStripeBankAccountLoginLinkQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountLoginLink }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockLinkStripeBankAccountLoginLinkQuery = (
  resolver: GraphQLResponseResolver<
    LinkStripeBankAccountLoginLinkQuery,
    LinkStripeBankAccountLoginLinkQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<
    LinkStripeBankAccountLoginLinkQuery,
    LinkStripeBankAccountLoginLinkQueryVariables
  >('LinkStripeBankAccountLoginLink', resolver, options)

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockWalletWithdrawFormQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { stripeAccountInfo, userPaymentSummary }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockWalletWithdrawFormQuery = (
  resolver: GraphQLResponseResolver<
    WalletWithdrawFormQuery,
    WalletWithdrawFormQueryVariables
  >,
  options?: RequestHandlerOptions,
) =>
  graphql.query<WalletWithdrawFormQuery, WalletWithdrawFormQueryVariables>(
    'WalletWithdrawForm',
    resolver,
    options,
  )

export type AvailableToAcceptBountyTypeKeySpecifier = (
  | 'isValid'
  | 'validationCode'
  | AvailableToAcceptBountyTypeKeySpecifier
)[]
export type AvailableToAcceptBountyTypeFieldPolicy = {
  isValid?: FieldPolicy<any> | FieldReadFunction<any>
  validationCode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BriefKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'isDefault'
  | 'name'
  | 'paymentSnapshot'
  | 'slug'
  | 'videoUrl'
  | BriefKeySpecifier
)[]
export type BriefFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  paymentSnapshot?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  videoUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChatMessageMetaKeySpecifier = ('id' | ChatMessageMetaKeySpecifier)[]
export type ChatMessageMetaFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChatMessageMetaGiftOfferKeySpecifier = (
  | 'createdAt'
  | 'giftType'
  | 'giftValue'
  | 'id'
  | 'offerStatus'
  | ChatMessageMetaGiftOfferKeySpecifier
)[]
export type ChatMessageMetaGiftOfferFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  giftType?: FieldPolicy<any> | FieldReadFunction<any>
  giftValue?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  offerStatus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChatMessageMetaSparkCodeKeySpecifier = (
  | 'bountyId'
  | 'createdAt'
  | 'id'
  | 'message'
  | 'sparkCodeAmount'
  | 'sparkCodeStatus'
  | ChatMessageMetaSparkCodeKeySpecifier
)[]
export type ChatMessageMetaSparkCodeFieldPolicy = {
  bountyId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  sparkCodeAmount?: FieldPolicy<any> | FieldReadFunction<any>
  sparkCodeStatus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CheckForNewVideoResultKeySpecifier = (
  | 'code'
  | 'message'
  | CheckForNewVideoResultKeySpecifier
)[]
export type CheckForNewVideoResultFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConversationKeySpecifier = (
  | 'avatarUrl'
  | 'id'
  | 'lastReceivedActionableMessage'
  | 'latestMessage'
  | 'messages'
  | 'name'
  | 'unreadCount'
  | ConversationKeySpecifier
)[]
export type ConversationFieldPolicy = {
  avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastReceivedActionableMessage?: FieldPolicy<any> | FieldReadFunction<any>
  latestMessage?: FieldPolicy<any> | FieldReadFunction<any>
  messages?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  unreadCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConversationListResponseKeySpecifier = (
  | 'count'
  | 'items'
  | 'skip'
  | 'take'
  | ConversationListResponseKeySpecifier
)[]
export type ConversationListResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  items?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  take?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateRetailBountyErrorKeySpecifier = (
  | 'bountyId'
  | 'code'
  | 'message'
  | CreateRetailBountyErrorKeySpecifier
)[]
export type CreateRetailBountyErrorFieldPolicy = {
  bountyId?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DeleteUserResponseKeySpecifier = (
  | 'code'
  | 'message'
  | 'success'
  | DeleteUserResponseKeySpecifier
)[]
export type DeleteUserResponseFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  success?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmailVerificationRequestResponseKeySpecifier = (
  | 'code'
  | 'message'
  | 'success'
  | EmailVerificationRequestResponseKeySpecifier
)[]
export type EmailVerificationRequestResponseFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  success?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferKeySpecifier = (
  | 'createdAt'
  | 'expiresAt'
  | 'id'
  | 'key'
  | 'message'
  | 'refType'
  | 'shopUrl'
  | 'status'
  | 'storeId'
  | 'storeLogo'
  | 'storeName'
  | 'type'
  | 'updatedAt'
  | GiftOfferKeySpecifier
)[]
export type GiftOfferFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  refType?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  storeLogo?: FieldPolicy<any> | FieldReadFunction<any>
  storeName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferBountyKeySpecifier = (
  | 'createdAt'
  | 'expiresAt'
  | 'id'
  | 'key'
  | 'message'
  | 'minBountyPayment'
  | 'offerValue'
  | 'refType'
  | 'shopUrl'
  | 'status'
  | 'storeId'
  | 'storeLogo'
  | 'storeName'
  | 'type'
  | 'updatedAt'
  | GiftOfferBountyKeySpecifier
)[]
export type GiftOfferBountyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  minBountyPayment?: FieldPolicy<any> | FieldReadFunction<any>
  offerValue?: FieldPolicy<any> | FieldReadFunction<any>
  refType?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  storeLogo?: FieldPolicy<any> | FieldReadFunction<any>
  storeName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferBountyDetailsKeySpecifier = (
  | 'id'
  | 'message'
  | 'minBountyPayment'
  | 'storeName'
  | GiftOfferBountyDetailsKeySpecifier
)[]
export type GiftOfferBountyDetailsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  minBountyPayment?: FieldPolicy<any> | FieldReadFunction<any>
  storeName?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferErrorKeySpecifier = (
  | 'code'
  | 'message'
  | GiftOfferErrorKeySpecifier
)[]
export type GiftOfferErrorFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferProductKeySpecifier = (
  | 'createdAt'
  | 'expiresAt'
  | 'id'
  | 'key'
  | 'message'
  | 'offerValue'
  | 'paymentSnapshot'
  | 'products'
  | 'refType'
  | 'shopUrl'
  | 'shopifyStoreOrderId'
  | 'status'
  | 'storeId'
  | 'storeLogo'
  | 'storeName'
  | 'type'
  | 'updatedAt'
  | GiftOfferProductKeySpecifier
)[]
export type GiftOfferProductFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  offerValue?: FieldPolicy<any> | FieldReadFunction<any>
  paymentSnapshot?: FieldPolicy<any> | FieldReadFunction<any>
  products?: FieldPolicy<any> | FieldReadFunction<any>
  refType?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  shopifyStoreOrderId?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  storeLogo?: FieldPolicy<any> | FieldReadFunction<any>
  storeName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferProductDetailOptionKeySpecifier = (
  | 'id'
  | 'imageUrl'
  | 'name'
  | 'price'
  | 'productLink'
  | 'quantity'
  | 'storeName'
  | 'variants'
  | GiftOfferProductDetailOptionKeySpecifier
)[]
export type GiftOfferProductDetailOptionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  productLink?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  storeName?: FieldPolicy<any> | FieldReadFunction<any>
  variants?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferProductDetailsKeySpecifier = (
  | 'email'
  | 'id'
  | 'message'
  | 'products'
  | GiftOfferProductDetailsKeySpecifier
)[]
export type GiftOfferProductDetailsFieldPolicy = {
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  products?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferProductOptionKeySpecifier = (
  | 'id'
  | 'imageUrl'
  | 'name'
  | 'price'
  | 'productLink'
  | 'quantity'
  | GiftOfferProductOptionKeySpecifier
)[]
export type GiftOfferProductOptionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  productLink?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GiftOfferProductVariantKeySpecifier = (
  | 'id'
  | 'name'
  | GiftOfferProductVariantKeySpecifier
)[]
export type GiftOfferProductVariantFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramContentKeySpecifier = (
  | 'commentCount'
  | 'contentId'
  | 'createdAt'
  | 'description'
  | 'id'
  | 'imageUrl'
  | 'instagramContentPolls'
  | 'likeCount'
  | 'shareCount'
  | 'title'
  | 'type'
  | 'updatedAt'
  | 'url'
  | 'userBountyId'
  | 'viewCount'
  | InstagramContentKeySpecifier
)[]
export type InstagramContentFieldPolicy = {
  commentCount?: FieldPolicy<any> | FieldReadFunction<any>
  contentId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  instagramContentPolls?: FieldPolicy<any> | FieldReadFunction<any>
  likeCount?: FieldPolicy<any> | FieldReadFunction<any>
  shareCount?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  userBountyId?: FieldPolicy<any> | FieldReadFunction<any>
  viewCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramContentPollKeySpecifier = (
  | 'commentCount'
  | 'createdAt'
  | 'id'
  | 'instagramContentId'
  | 'likeCount'
  | 'shareCount'
  | 'viewCount'
  | InstagramContentPollKeySpecifier
)[]
export type InstagramContentPollFieldPolicy = {
  commentCount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  instagramContentId?: FieldPolicy<any> | FieldReadFunction<any>
  likeCount?: FieldPolicy<any> | FieldReadFunction<any>
  shareCount?: FieldPolicy<any> | FieldReadFunction<any>
  viewCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramProfileKeySpecifier = (
  | 'bioDescription'
  | 'createdAt'
  | 'displayName'
  | 'followerCount'
  | 'followingCount'
  | 'id'
  | 'likesCount'
  | 'profileLink'
  | 'profileName'
  | 'type'
  | 'verified'
  | InstagramProfileKeySpecifier
)[]
export type InstagramProfileFieldPolicy = {
  bioDescription?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  followerCount?: FieldPolicy<any> | FieldReadFunction<any>
  followingCount?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likesCount?: FieldPolicy<any> | FieldReadFunction<any>
  profileLink?: FieldPolicy<any> | FieldReadFunction<any>
  profileName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  verified?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramProfileCheckResultKeySpecifier = (
  | 'code'
  | 'data'
  | InstagramProfileCheckResultKeySpecifier
)[]
export type InstagramProfileCheckResultFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramProfileCheckResultDataKeySpecifier = (
  | 'bioDescription'
  | 'displayName'
  | 'followerCount'
  | 'followingCount'
  | 'id'
  | 'isPrivate'
  | 'profileImage'
  | 'profileLink'
  | 'profileName'
  | InstagramProfileCheckResultDataKeySpecifier
)[]
export type InstagramProfileCheckResultDataFieldPolicy = {
  bioDescription?: FieldPolicy<any> | FieldReadFunction<any>
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  followerCount?: FieldPolicy<any> | FieldReadFunction<any>
  followingCount?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isPrivate?: FieldPolicy<any> | FieldReadFunction<any>
  profileImage?: FieldPolicy<any> | FieldReadFunction<any>
  profileLink?: FieldPolicy<any> | FieldReadFunction<any>
  profileName?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InstagramProfileConfirmResultKeySpecifier = (
  | 'code'
  | 'data'
  | 'profile'
  | InstagramProfileConfirmResultKeySpecifier
)[]
export type InstagramProfileConfirmResultFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  profile?: FieldPolicy<any> | FieldReadFunction<any>
}
export type JoinCommunityResultKeySpecifier = (
  | 'bounty'
  | 'code'
  | 'success'
  | JoinCommunityResultKeySpecifier
)[]
export type JoinCommunityResultFieldPolicy = {
  bounty?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  success?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MessageKeySpecifier = (
  | 'id'
  | 'isSystem'
  | 'message'
  | 'meta'
  | 'readAt'
  | 'sender'
  | 'sentAt'
  | 'type'
  | MessageKeySpecifier
)[]
export type MessageFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isSystem?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  readAt?: FieldPolicy<any> | FieldReadFunction<any>
  sender?: FieldPolicy<any> | FieldReadFunction<any>
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MessageSenderKeySpecifier = (
  | 'avatarUrl'
  | 'id'
  | 'name'
  | MessageSenderKeySpecifier
)[]
export type MessageSenderFieldPolicy = {
  avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MinimumPayoutInformationKeySpecifier = (
  | 'isEligibleForMinimumPayout'
  | 'minimumPayoutAmount'
  | MinimumPayoutInformationKeySpecifier
)[]
export type MinimumPayoutInformationFieldPolicy = {
  isEligibleForMinimumPayout?: FieldPolicy<any> | FieldReadFunction<any>
  minimumPayoutAmount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MutationKeySpecifier = (
  | 'acceptBounty'
  | 'acceptSparkAdCodeRequest'
  | 'addPushToken'
  | 'checkInstagramProfileName'
  | 'claimGiftOffer'
  | 'completeWelcomeFlow'
  | 'confirmInstagramProfileName'
  | 'createCrossPostBounty'
  | 'createRetailBounty'
  | 'createUserProfileInfo'
  | 'deleteUser'
  | 'errorTest'
  | 'errorTest2'
  | 'forceCheckForNewVideo'
  | 'forceRefreshPaymentSummary'
  | 'joinCommunity'
  | 'markBountyOrderDelivered'
  | 'markChatRead'
  | 'markMessageRead'
  | 'redeemGiftOfferBounty'
  | 'redeemGiftOfferProduct'
  | 'requestEmailVerification'
  | 'requestExtensionForBounty'
  | 'startStripeAccountLinking'
  | 'submitEmailVerificationCode'
  | 'submitPayoutRequest'
  | 'submitPayoutRequestStripe'
  | 'submitUserMediaFeedback'
  | 'termsAccepted'
  | 'updateNotificationGroup'
  | 'updateNotificationPreference'
  | 'updateRetailSurveyAnswers'
  | 'updateUser'
  | 'updateUserProfileInfo'
  | 'verifyTikTokAuth'
  | MutationKeySpecifier
)[]
export type MutationFieldPolicy = {
  acceptBounty?: FieldPolicy<any> | FieldReadFunction<any>
  acceptSparkAdCodeRequest?: FieldPolicy<any> | FieldReadFunction<any>
  addPushToken?: FieldPolicy<any> | FieldReadFunction<any>
  checkInstagramProfileName?: FieldPolicy<any> | FieldReadFunction<any>
  claimGiftOffer?: FieldPolicy<any> | FieldReadFunction<any>
  completeWelcomeFlow?: FieldPolicy<any> | FieldReadFunction<any>
  confirmInstagramProfileName?: FieldPolicy<any> | FieldReadFunction<any>
  createCrossPostBounty?: FieldPolicy<any> | FieldReadFunction<any>
  createRetailBounty?: FieldPolicy<any> | FieldReadFunction<any>
  createUserProfileInfo?: FieldPolicy<any> | FieldReadFunction<any>
  deleteUser?: FieldPolicy<any> | FieldReadFunction<any>
  errorTest?: FieldPolicy<any> | FieldReadFunction<any>
  errorTest2?: FieldPolicy<any> | FieldReadFunction<any>
  forceCheckForNewVideo?: FieldPolicy<any> | FieldReadFunction<any>
  forceRefreshPaymentSummary?: FieldPolicy<any> | FieldReadFunction<any>
  joinCommunity?: FieldPolicy<any> | FieldReadFunction<any>
  markBountyOrderDelivered?: FieldPolicy<any> | FieldReadFunction<any>
  markChatRead?: FieldPolicy<any> | FieldReadFunction<any>
  markMessageRead?: FieldPolicy<any> | FieldReadFunction<any>
  redeemGiftOfferBounty?: FieldPolicy<any> | FieldReadFunction<any>
  redeemGiftOfferProduct?: FieldPolicy<any> | FieldReadFunction<any>
  requestEmailVerification?: FieldPolicy<any> | FieldReadFunction<any>
  requestExtensionForBounty?: FieldPolicy<any> | FieldReadFunction<any>
  startStripeAccountLinking?: FieldPolicy<any> | FieldReadFunction<any>
  submitEmailVerificationCode?: FieldPolicy<any> | FieldReadFunction<any>
  submitPayoutRequest?: FieldPolicy<any> | FieldReadFunction<any>
  submitPayoutRequestStripe?: FieldPolicy<any> | FieldReadFunction<any>
  submitUserMediaFeedback?: FieldPolicy<any> | FieldReadFunction<any>
  termsAccepted?: FieldPolicy<any> | FieldReadFunction<any>
  updateNotificationGroup?: FieldPolicy<any> | FieldReadFunction<any>
  updateNotificationPreference?: FieldPolicy<any> | FieldReadFunction<any>
  updateRetailSurveyAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  updateUser?: FieldPolicy<any> | FieldReadFunction<any>
  updateUserProfileInfo?: FieldPolicy<any> | FieldReadFunction<any>
  verifyTikTokAuth?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PayoutForViewsByBountyResponseKeySpecifier = (
  | 'costPaymentReduction'
  | 'isMinimumPayout'
  | 'minimumPayout'
  | 'payout'
  | 'views'
  | PayoutForViewsByBountyResponseKeySpecifier
)[]
export type PayoutForViewsByBountyResponseFieldPolicy = {
  costPaymentReduction?: FieldPolicy<any> | FieldReadFunction<any>
  isMinimumPayout?: FieldPolicy<any> | FieldReadFunction<any>
  minimumPayout?: FieldPolicy<any> | FieldReadFunction<any>
  payout?: FieldPolicy<any> | FieldReadFunction<any>
  views?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PayoutForViewsByStoreResponseKeySpecifier = (
  | 'isMinimumPayout'
  | 'minimumPayout'
  | 'payout'
  | 'views'
  | PayoutForViewsByStoreResponseKeySpecifier
)[]
export type PayoutForViewsByStoreResponseFieldPolicy = {
  isMinimumPayout?: FieldPolicy<any> | FieldReadFunction<any>
  minimumPayout?: FieldPolicy<any> | FieldReadFunction<any>
  payout?: FieldPolicy<any> | FieldReadFunction<any>
  views?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductInstructionKeySpecifier = (
  | 'createdAt'
  | 'features'
  | 'headline'
  | 'id'
  | 'isDefault'
  | 'name'
  | 'storeId'
  | 'updatedAt'
  | ProductInstructionKeySpecifier
)[]
export type ProductInstructionFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  features?: FieldPolicy<any> | FieldReadFunction<any>
  headline?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
  | 'bounties'
  | 'bounty'
  | 'bulkStoreCPMStartingPayout'
  | 'conversation'
  | 'conversations'
  | 'currentUser'
  | 'currentUserProfile'
  | 'giftOffer'
  | 'giftOfferDetails'
  | 'giftOffers'
  | 'instagramProfile'
  | 'isRetailEnabled'
  | 'latestTikTokUserVideos'
  | 'mediaFeedbacks'
  | 'notificationPreferences'
  | 'payoutForViewsByBounty'
  | 'payoutForViewsByStore'
  | 'recommendedBounties'
  | 'recommendedStores'
  | 'retailLocations'
  | 'selectableBriefs'
  | 'storeCPMStartingPayout'
  | 'stripeAccountInfo'
  | 'stripeAccountLoginLink'
  | 'systemMessages'
  | 'tikTokProfile'
  | 'tikTokUserInfoDetails'
  | 'topVideos'
  | 'userPaymentSummary'
  | 'userPayments'
  | 'userPayoutRequests'
  | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
  bounties?: FieldPolicy<any> | FieldReadFunction<any>
  bounty?: FieldPolicy<any> | FieldReadFunction<any>
  bulkStoreCPMStartingPayout?: FieldPolicy<any> | FieldReadFunction<any>
  conversation?: FieldPolicy<any> | FieldReadFunction<any>
  conversations?: FieldPolicy<any> | FieldReadFunction<any>
  currentUser?: FieldPolicy<any> | FieldReadFunction<any>
  currentUserProfile?: FieldPolicy<any> | FieldReadFunction<any>
  giftOffer?: FieldPolicy<any> | FieldReadFunction<any>
  giftOfferDetails?: FieldPolicy<any> | FieldReadFunction<any>
  giftOffers?: FieldPolicy<any> | FieldReadFunction<any>
  instagramProfile?: FieldPolicy<any> | FieldReadFunction<any>
  isRetailEnabled?: FieldPolicy<any> | FieldReadFunction<any>
  latestTikTokUserVideos?: FieldPolicy<any> | FieldReadFunction<any>
  mediaFeedbacks?: FieldPolicy<any> | FieldReadFunction<any>
  notificationPreferences?: FieldPolicy<any> | FieldReadFunction<any>
  payoutForViewsByBounty?: FieldPolicy<any> | FieldReadFunction<any>
  payoutForViewsByStore?: FieldPolicy<any> | FieldReadFunction<any>
  recommendedBounties?: FieldPolicy<any> | FieldReadFunction<any>
  recommendedStores?: FieldPolicy<any> | FieldReadFunction<any>
  retailLocations?: FieldPolicy<any> | FieldReadFunction<any>
  selectableBriefs?: FieldPolicy<any> | FieldReadFunction<any>
  storeCPMStartingPayout?: FieldPolicy<any> | FieldReadFunction<any>
  stripeAccountInfo?: FieldPolicy<any> | FieldReadFunction<any>
  stripeAccountLoginLink?: FieldPolicy<any> | FieldReadFunction<any>
  systemMessages?: FieldPolicy<any> | FieldReadFunction<any>
  tikTokProfile?: FieldPolicy<any> | FieldReadFunction<any>
  tikTokUserInfoDetails?: FieldPolicy<any> | FieldReadFunction<any>
  topVideos?: FieldPolicy<any> | FieldReadFunction<any>
  userPaymentSummary?: FieldPolicy<any> | FieldReadFunction<any>
  userPayments?: FieldPolicy<any> | FieldReadFunction<any>
  userPayoutRequests?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RedeemGiftOfferProductResultKeySpecifier = (
  | 'error'
  | 'message'
  | 'success'
  | RedeemGiftOfferProductResultKeySpecifier
)[]
export type RedeemGiftOfferProductResultFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  success?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RetailBriefKeySpecifier = (
  | 'features'
  | 'headline'
  | RetailBriefKeySpecifier
)[]
export type RetailBriefFieldPolicy = {
  features?: FieldPolicy<any> | FieldReadFunction<any>
  headline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ShopifyStoreKeySpecifier = (
  | 'active'
  | 'brandedHashtag'
  | 'categories'
  | 'hasActiveSubscription'
  | 'id'
  | 'instagramUsername'
  | 'isSnippetEnabled'
  | 'logoImgLink'
  | 'minBountyPayment'
  | 'name'
  | 'retailBrief'
  | 'retailLocations'
  | 'retailLocatorUrl'
  | 'shopUrl'
  | 'tags'
  | 'tikTokUsername'
  | ShopifyStoreKeySpecifier
)[]
export type ShopifyStoreFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>
  brandedHashtag?: FieldPolicy<any> | FieldReadFunction<any>
  categories?: FieldPolicy<any> | FieldReadFunction<any>
  hasActiveSubscription?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  instagramUsername?: FieldPolicy<any> | FieldReadFunction<any>
  isSnippetEnabled?: FieldPolicy<any> | FieldReadFunction<any>
  logoImgLink?: FieldPolicy<any> | FieldReadFunction<any>
  minBountyPayment?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  retailBrief?: FieldPolicy<any> | FieldReadFunction<any>
  retailLocations?: FieldPolicy<any> | FieldReadFunction<any>
  retailLocatorUrl?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tikTokUsername?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ShopifyStoreDealKeySpecifier = (
  | 'description'
  | 'endDate'
  | 'startDate'
  | 'terms'
  | 'title'
  | ShopifyStoreDealKeySpecifier
)[]
export type ShopifyStoreDealFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  endDate?: FieldPolicy<any> | FieldReadFunction<any>
  startDate?: FieldPolicy<any> | FieldReadFunction<any>
  terms?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ShopifyStoreOrderKeySpecifier = (
  | 'bounty'
  | 'cancelledAt'
  | 'createdAt'
  | 'deliveredAt'
  | 'firstItemName'
  | 'fulfilledAt'
  | 'id'
  | 'inTransitAt'
  | 'shippedAt'
  | 'status'
  | 'storeId'
  | 'updatedAt'
  | ShopifyStoreOrderKeySpecifier
)[]
export type ShopifyStoreOrderFieldPolicy = {
  bounty?: FieldPolicy<any> | FieldReadFunction<any>
  cancelledAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deliveredAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstItemName?: FieldPolicy<any> | FieldReadFunction<any>
  fulfilledAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  inTransitAt?: FieldPolicy<any> | FieldReadFunction<any>
  shippedAt?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ShopifyStorePreviewKeySpecifier = (
  | 'deals'
  | 'featured'
  | 'headerImgLinkPrimary'
  | 'headerImgLinkSecondary'
  | 'id'
  | 'logo'
  | 'minBountyPayment'
  | 'name'
  | 'shopUrl'
  | 'slug'
  | 'startingCPM'
  | 'startingCPMViews'
  | ShopifyStorePreviewKeySpecifier
)[]
export type ShopifyStorePreviewFieldPolicy = {
  deals?: FieldPolicy<any> | FieldReadFunction<any>
  featured?: FieldPolicy<any> | FieldReadFunction<any>
  headerImgLinkPrimary?: FieldPolicy<any> | FieldReadFunction<any>
  headerImgLinkSecondary?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  logo?: FieldPolicy<any> | FieldReadFunction<any>
  minBountyPayment?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  startingCPM?: FieldPolicy<any> | FieldReadFunction<any>
  startingCPMViews?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ShopifyStoreProductKeySpecifier = (
  | 'enabled'
  | 'id'
  | 'imgLink'
  | 'productId'
  | 'productInstruction'
  | 'productName'
  | 'productSku'
  | 'storeId'
  | ShopifyStoreProductKeySpecifier
)[]
export type ShopifyStoreProductFieldPolicy = {
  enabled?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imgLink?: FieldPolicy<any> | FieldReadFunction<any>
  productId?: FieldPolicy<any> | FieldReadFunction<any>
  productInstruction?: FieldPolicy<any> | FieldReadFunction<any>
  productName?: FieldPolicy<any> | FieldReadFunction<any>
  productSku?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SparkAdCodeRequestKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'id'
  | 'requestLog'
  | 'status'
  | 'updatedAt'
  | 'userId'
  | SparkAdCodeRequestKeySpecifier
)[]
export type SparkAdCodeRequestFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  requestLog?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SparkAdCodeRequestLogKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'message'
  | 'status'
  | SparkAdCodeRequestLogKeySpecifier
)[]
export type SparkAdCodeRequestLogFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StoreCPMStartingPayoutKeySpecifier = (
  | 'startingCPM'
  | 'startingCPMViews'
  | 'storeUrl'
  | StoreCPMStartingPayoutKeySpecifier
)[]
export type StoreCPMStartingPayoutFieldPolicy = {
  startingCPM?: FieldPolicy<any> | FieldReadFunction<any>
  startingCPMViews?: FieldPolicy<any> | FieldReadFunction<any>
  storeUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StripeAccountInfoKeySpecifier = (
  | 'accountExists'
  | 'accountId'
  | 'last4'
  | 'payoutsEnabled'
  | 'pendingBalance'
  | 'totalBalance'
  | StripeAccountInfoKeySpecifier
)[]
export type StripeAccountInfoFieldPolicy = {
  accountExists?: FieldPolicy<any> | FieldReadFunction<any>
  accountId?: FieldPolicy<any> | FieldReadFunction<any>
  last4?: FieldPolicy<any> | FieldReadFunction<any>
  payoutsEnabled?: FieldPolicy<any> | FieldReadFunction<any>
  pendingBalance?: FieldPolicy<any> | FieldReadFunction<any>
  totalBalance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StripeAccountLinkingResultKeySpecifier = (
  | 'id'
  | 'url'
  | StripeAccountLinkingResultKeySpecifier
)[]
export type StripeAccountLinkingResultFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StripeAccountLoginLinkResultKeySpecifier = (
  | 'url'
  | StripeAccountLoginLinkResultKeySpecifier
)[]
export type StripeAccountLoginLinkResultFieldPolicy = {
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TikTokProfileKeySpecifier = (
  | 'displayName'
  | 'followerCount'
  | 'id'
  | 'profileImage'
  | 'profileLink'
  | 'profileName'
  | 'type'
  | 'verified'
  | TikTokProfileKeySpecifier
)[]
export type TikTokProfileFieldPolicy = {
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  followerCount?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  profileImage?: FieldPolicy<any> | FieldReadFunction<any>
  profileLink?: FieldPolicy<any> | FieldReadFunction<any>
  profileName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  verified?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TikTokUserInfoDetailsKeySpecifier = (
  | 'avatarUrl'
  | 'bioDescription'
  | 'displayName'
  | 'followerCount'
  | 'profileLink'
  | TikTokUserInfoDetailsKeySpecifier
)[]
export type TikTokUserInfoDetailsFieldPolicy = {
  avatarUrl?: FieldPolicy<any> | FieldReadFunction<any>
  bioDescription?: FieldPolicy<any> | FieldReadFunction<any>
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  followerCount?: FieldPolicy<any> | FieldReadFunction<any>
  profileLink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TikTokUserVideo_RAWKeySpecifier = (
  | 'comment_count'
  | 'cover_image_url'
  | 'create_time'
  | 'duration'
  | 'embed_html'
  | 'embed_link'
  | 'id'
  | 'like_count'
  | 'share_count'
  | 'share_url'
  | 'title'
  | 'video_description'
  | 'view_count'
  | TikTokUserVideo_RAWKeySpecifier
)[]
export type TikTokUserVideo_RAWFieldPolicy = {
  comment_count?: FieldPolicy<any> | FieldReadFunction<any>
  cover_image_url?: FieldPolicy<any> | FieldReadFunction<any>
  create_time?: FieldPolicy<any> | FieldReadFunction<any>
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  embed_html?: FieldPolicy<any> | FieldReadFunction<any>
  embed_link?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  like_count?: FieldPolicy<any> | FieldReadFunction<any>
  share_count?: FieldPolicy<any> | FieldReadFunction<any>
  share_url?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  video_description?: FieldPolicy<any> | FieldReadFunction<any>
  view_count?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TikTokVideoKeySpecifier = (
  | 'commentCount'
  | 'createdAt'
  | 'feedbackMessage'
  | 'id'
  | 'likeCount'
  | 'shareCount'
  | 'sparkAdCodeRequests'
  | 'tikTokVideoPolls'
  | 'updatedAt'
  | 'videoDescription'
  | 'videoId'
  | 'videoTitle'
  | 'videoUrl'
  | 'viewCount'
  | TikTokVideoKeySpecifier
)[]
export type TikTokVideoFieldPolicy = {
  commentCount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feedbackMessage?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likeCount?: FieldPolicy<any> | FieldReadFunction<any>
  shareCount?: FieldPolicy<any> | FieldReadFunction<any>
  sparkAdCodeRequests?: FieldPolicy<any> | FieldReadFunction<any>
  tikTokVideoPolls?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  videoDescription?: FieldPolicy<any> | FieldReadFunction<any>
  videoId?: FieldPolicy<any> | FieldReadFunction<any>
  videoTitle?: FieldPolicy<any> | FieldReadFunction<any>
  videoUrl?: FieldPolicy<any> | FieldReadFunction<any>
  viewCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TikTokVideoPollKeySpecifier = (
  | 'commentCount'
  | 'createdAt'
  | 'id'
  | 'likeCount'
  | 'shareCount'
  | 'viewCount'
  | TikTokVideoPollKeySpecifier
)[]
export type TikTokVideoPollFieldPolicy = {
  commentCount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likeCount?: FieldPolicy<any> | FieldReadFunction<any>
  shareCount?: FieldPolicy<any> | FieldReadFunction<any>
  viewCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TopVideoKeySpecifier = (
  | 'commentCount'
  | 'createdAt'
  | 'id'
  | 'likeCount'
  | 'product'
  | 'shareCount'
  | 'store'
  | 'updatedAt'
  | 'userSocialProfile'
  | 'videoDescription'
  | 'videoId'
  | 'videoTitle'
  | 'videoUrl'
  | 'viewCount'
  | TopVideoKeySpecifier
)[]
export type TopVideoFieldPolicy = {
  commentCount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likeCount?: FieldPolicy<any> | FieldReadFunction<any>
  product?: FieldPolicy<any> | FieldReadFunction<any>
  shareCount?: FieldPolicy<any> | FieldReadFunction<any>
  store?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userSocialProfile?: FieldPolicy<any> | FieldReadFunction<any>
  videoDescription?: FieldPolicy<any> | FieldReadFunction<any>
  videoId?: FieldPolicy<any> | FieldReadFunction<any>
  videoTitle?: FieldPolicy<any> | FieldReadFunction<any>
  videoUrl?: FieldPolicy<any> | FieldReadFunction<any>
  viewCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TopVideoProductKeySpecifier = (
  | 'id'
  | 'imgLink'
  | 'productName'
  | TopVideoProductKeySpecifier
)[]
export type TopVideoProductFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imgLink?: FieldPolicy<any> | FieldReadFunction<any>
  productName?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TopVideoSocialProfileKeySpecifier = (
  | 'bioDescription'
  | 'displayName'
  | 'followerCount'
  | 'followingCount'
  | 'id'
  | 'likesCount'
  | 'profileLink'
  | 'profileName'
  | 'type'
  | TopVideoSocialProfileKeySpecifier
)[]
export type TopVideoSocialProfileFieldPolicy = {
  bioDescription?: FieldPolicy<any> | FieldReadFunction<any>
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  followerCount?: FieldPolicy<any> | FieldReadFunction<any>
  followingCount?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likesCount?: FieldPolicy<any> | FieldReadFunction<any>
  profileLink?: FieldPolicy<any> | FieldReadFunction<any>
  profileName?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TopVideoStoreKeySpecifier = (
  | 'categories'
  | 'id'
  | 'logoImgLink'
  | 'name'
  | 'shopUrl'
  | 'tags'
  | TopVideoStoreKeySpecifier
)[]
export type TopVideoStoreFieldPolicy = {
  categories?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  logoImgLink?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  shopUrl?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserKeySpecifier = (
  | 'acceptedLatestTerms'
  | 'communities'
  | 'createdAt'
  | 'email'
  | 'emailVerified'
  | 'id'
  | 'isWelcomeFlowCompleted'
  | 'lastLogin'
  | 'sms'
  | 'smsVerified'
  | UserKeySpecifier
)[]
export type UserFieldPolicy = {
  acceptedLatestTerms?: FieldPolicy<any> | FieldReadFunction<any>
  communities?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  emailVerified?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isWelcomeFlowCompleted?: FieldPolicy<any> | FieldReadFunction<any>
  lastLogin?: FieldPolicy<any> | FieldReadFunction<any>
  sms?: FieldPolicy<any> | FieldReadFunction<any>
  smsVerified?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBountyKeySpecifier = (
  | 'acceptedAt'
  | 'availableToAcceptBounty'
  | 'bountyDefaultBrief'
  | 'brief'
  | 'briefId'
  | 'canPostToOtherSocials'
  | 'completedAt'
  | 'contentTypeSubmitted'
  | 'contentTypesAllowed'
  | 'createdAt'
  | 'expiresAt'
  | 'extensionInformation'
  | 'id'
  | 'instagramContent'
  | 'isExpired'
  | 'isNewPayoutStructure'
  | 'liveAt'
  | 'liveEndsAt'
  | 'minimumPayoutInformation'
  | 'name'
  | 'order'
  | 'paymentSnapshot'
  | 'product'
  | 'productId'
  | 'rating'
  | 'rejectedAt'
  | 'shopifyStoreOrderId'
  | 'showOver21AgeGate'
  | 'status'
  | 'store'
  | 'storeId'
  | 'surveyAnswers'
  | 'tikTokVideo'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'userPayment'
  | 'userPaymentId'
  | UserBountyKeySpecifier
)[]
export type UserBountyFieldPolicy = {
  acceptedAt?: FieldPolicy<any> | FieldReadFunction<any>
  availableToAcceptBounty?: FieldPolicy<any> | FieldReadFunction<any>
  bountyDefaultBrief?: FieldPolicy<any> | FieldReadFunction<any>
  brief?: FieldPolicy<any> | FieldReadFunction<any>
  briefId?: FieldPolicy<any> | FieldReadFunction<any>
  canPostToOtherSocials?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  contentTypeSubmitted?: FieldPolicy<any> | FieldReadFunction<any>
  contentTypesAllowed?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
  extensionInformation?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  instagramContent?: FieldPolicy<any> | FieldReadFunction<any>
  isExpired?: FieldPolicy<any> | FieldReadFunction<any>
  isNewPayoutStructure?: FieldPolicy<any> | FieldReadFunction<any>
  liveAt?: FieldPolicy<any> | FieldReadFunction<any>
  liveEndsAt?: FieldPolicy<any> | FieldReadFunction<any>
  minimumPayoutInformation?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  order?: FieldPolicy<any> | FieldReadFunction<any>
  paymentSnapshot?: FieldPolicy<any> | FieldReadFunction<any>
  product?: FieldPolicy<any> | FieldReadFunction<any>
  productId?: FieldPolicy<any> | FieldReadFunction<any>
  rating?: FieldPolicy<any> | FieldReadFunction<any>
  rejectedAt?: FieldPolicy<any> | FieldReadFunction<any>
  shopifyStoreOrderId?: FieldPolicy<any> | FieldReadFunction<any>
  showOver21AgeGate?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  store?: FieldPolicy<any> | FieldReadFunction<any>
  storeId?: FieldPolicy<any> | FieldReadFunction<any>
  surveyAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  tikTokVideo?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
  userPayment?: FieldPolicy<any> | FieldReadFunction<any>
  userPaymentId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBountyExpirationExtensionKeySpecifier = (
  | 'availableToExtendBounty'
  | 'availableToExtendDate'
  | 'reason'
  | UserBountyExpirationExtensionKeySpecifier
)[]
export type UserBountyExpirationExtensionFieldPolicy = {
  availableToExtendBounty?: FieldPolicy<any> | FieldReadFunction<any>
  availableToExtendDate?: FieldPolicy<any> | FieldReadFunction<any>
  reason?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBountyRatingKeySpecifier = (
  | 'bonuses'
  | 'feedbackMessage'
  | 'penalties'
  | UserBountyRatingKeySpecifier
)[]
export type UserBountyRatingFieldPolicy = {
  bonuses?: FieldPolicy<any> | FieldReadFunction<any>
  feedbackMessage?: FieldPolicy<any> | FieldReadFunction<any>
  penalties?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBountyResponseKeySpecifier = (
  | 'count'
  | 'items'
  | 'skip'
  | 'take'
  | UserBountyResponseKeySpecifier
)[]
export type UserBountyResponseFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  items?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  take?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBounty_PaymentSnapshotKeySpecifier = (
  | 'minBountyPayment'
  | 'productGiftConfig'
  | UserBounty_PaymentSnapshotKeySpecifier
)[]
export type UserBounty_PaymentSnapshotFieldPolicy = {
  minBountyPayment?: FieldPolicy<any> | FieldReadFunction<any>
  productGiftConfig?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBounty_ProductGiftConfigKeySpecifier = (
  | 'hasMinPayment'
  | 'noPayment'
  | 'payPerViews'
  | UserBounty_ProductGiftConfigKeySpecifier
)[]
export type UserBounty_ProductGiftConfigFieldPolicy = {
  hasMinPayment?: FieldPolicy<any> | FieldReadFunction<any>
  noPayment?: FieldPolicy<any> | FieldReadFunction<any>
  payPerViews?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserMediaFeedbackKeySpecifier = (
  | 'author'
  | 'campaign'
  | 'createdAt'
  | 'experimentId'
  | 'fileLink'
  | 'id'
  | 'message'
  | 'notificationMedium'
  | 'responseId'
  | 'surveyMedium'
  | 'transcript'
  | UserMediaFeedbackKeySpecifier
)[]
export type UserMediaFeedbackFieldPolicy = {
  author?: FieldPolicy<any> | FieldReadFunction<any>
  campaign?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  experimentId?: FieldPolicy<any> | FieldReadFunction<any>
  fileLink?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  notificationMedium?: FieldPolicy<any> | FieldReadFunction<any>
  responseId?: FieldPolicy<any> | FieldReadFunction<any>
  surveyMedium?: FieldPolicy<any> | FieldReadFunction<any>
  transcript?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserNotificationPreferencesKeySpecifier = (
  | 'emailGeneralNotifications'
  | 'emailNotifications'
  | 'id'
  | 'pushGeneralNotifications'
  | 'pushNotifications'
  | 'smsGeneralNotifications'
  | 'smsNotifications'
  | UserNotificationPreferencesKeySpecifier
)[]
export type UserNotificationPreferencesFieldPolicy = {
  emailGeneralNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  emailNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  pushGeneralNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  pushNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  smsGeneralNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  smsNotifications?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserNotificationPreferences_MediumGroupConfigKeySpecifier = (
  | 'BOUNTY_FEEDBACK'
  | 'MONEY_AVAILABLE'
  | 'POST_REMINDERS'
  | 'PRODUCT_BOUGHT'
  | 'SPARK_CODES'
  | 'SPECIAL_OFFERS'
  | 'SPECIAL_OFFERS_NETWORK'
  | 'UPDATES_ABOUT_BOUNTY'
  | UserNotificationPreferences_MediumGroupConfigKeySpecifier
)[]
export type UserNotificationPreferences_MediumGroupConfigFieldPolicy = {
  BOUNTY_FEEDBACK?: FieldPolicy<any> | FieldReadFunction<any>
  MONEY_AVAILABLE?: FieldPolicy<any> | FieldReadFunction<any>
  POST_REMINDERS?: FieldPolicy<any> | FieldReadFunction<any>
  PRODUCT_BOUGHT?: FieldPolicy<any> | FieldReadFunction<any>
  SPARK_CODES?: FieldPolicy<any> | FieldReadFunction<any>
  SPECIAL_OFFERS?: FieldPolicy<any> | FieldReadFunction<any>
  SPECIAL_OFFERS_NETWORK?: FieldPolicy<any> | FieldReadFunction<any>
  UPDATES_ABOUT_BOUNTY?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPaymentKeySpecifier = (
  | 'approvedAt'
  | 'createdAt'
  | 'deniedAt'
  | 'estimatedFundsAvailableDate'
  | 'failedAt'
  | 'failureMessage'
  | 'id'
  | 'message'
  | 'settledAt'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'value'
  | UserPaymentKeySpecifier
)[]
export type UserPaymentFieldPolicy = {
  approvedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deniedAt?: FieldPolicy<any> | FieldReadFunction<any>
  estimatedFundsAvailableDate?: FieldPolicy<any> | FieldReadFunction<any>
  failedAt?: FieldPolicy<any> | FieldReadFunction<any>
  failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  settledAt?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPaymentSummaryKeySpecifier = (
  | 'availableBalance'
  | 'id'
  | 'lastPayoutDate'
  | 'lastWithdrawalDate'
  | 'pendingPayments'
  | 'pendingWithdrawals'
  | 'settledBalance'
  | 'totalEarned'
  | 'updatedAt'
  | 'withdrawnBalance'
  | UserPaymentSummaryKeySpecifier
)[]
export type UserPaymentSummaryFieldPolicy = {
  availableBalance?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastPayoutDate?: FieldPolicy<any> | FieldReadFunction<any>
  lastWithdrawalDate?: FieldPolicy<any> | FieldReadFunction<any>
  pendingPayments?: FieldPolicy<any> | FieldReadFunction<any>
  pendingWithdrawals?: FieldPolicy<any> | FieldReadFunction<any>
  settledBalance?: FieldPolicy<any> | FieldReadFunction<any>
  totalEarned?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawnBalance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPayoutRequestKeySpecifier = (
  | 'amount'
  | 'approvedAt'
  | 'completedAt'
  | 'createdAt'
  | 'deniedAt'
  | 'feedbackMessage'
  | 'id'
  | 'payment'
  | 'paymentInfo'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userInfo'
  | UserPayoutRequestKeySpecifier
)[]
export type UserPayoutRequestFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  approvedAt?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deniedAt?: FieldPolicy<any> | FieldReadFunction<any>
  feedbackMessage?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  payment?: FieldPolicy<any> | FieldReadFunction<any>
  paymentInfo?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPayoutRequestResponseKeySpecifier = (
  | 'code'
  | 'message'
  | 'success'
  | UserPayoutRequestResponseKeySpecifier
)[]
export type UserPayoutRequestResponseFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  success?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserProfileInfoKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'city'
  | 'country'
  | 'createdAt'
  | 'firstName'
  | 'id'
  | 'lastName'
  | 'postalCode'
  | 'province'
  | 'state'
  | 'suburb'
  | 'updatedAt'
  | 'zip'
  | UserProfileInfoKeySpecifier
)[]
export type UserProfileInfoFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>
  address2?: FieldPolicy<any> | FieldReadFunction<any>
  city?: FieldPolicy<any> | FieldReadFunction<any>
  country?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstName?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  lastName?: FieldPolicy<any> | FieldReadFunction<any>
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>
  province?: FieldPolicy<any> | FieldReadFunction<any>
  state?: FieldPolicy<any> | FieldReadFunction<any>
  suburb?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  zip?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
  AvailableToAcceptBountyType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AvailableToAcceptBountyTypeKeySpecifier
      | (() => undefined | AvailableToAcceptBountyTypeKeySpecifier)
    fields?: AvailableToAcceptBountyTypeFieldPolicy
  }
  Brief?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BriefKeySpecifier
      | (() => undefined | BriefKeySpecifier)
    fields?: BriefFieldPolicy
  }
  ChatMessageMeta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChatMessageMetaKeySpecifier
      | (() => undefined | ChatMessageMetaKeySpecifier)
    fields?: ChatMessageMetaFieldPolicy
  }
  ChatMessageMetaGiftOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChatMessageMetaGiftOfferKeySpecifier
      | (() => undefined | ChatMessageMetaGiftOfferKeySpecifier)
    fields?: ChatMessageMetaGiftOfferFieldPolicy
  }
  ChatMessageMetaSparkCode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChatMessageMetaSparkCodeKeySpecifier
      | (() => undefined | ChatMessageMetaSparkCodeKeySpecifier)
    fields?: ChatMessageMetaSparkCodeFieldPolicy
  }
  CheckForNewVideoResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CheckForNewVideoResultKeySpecifier
      | (() => undefined | CheckForNewVideoResultKeySpecifier)
    fields?: CheckForNewVideoResultFieldPolicy
  }
  Conversation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationKeySpecifier
      | (() => undefined | ConversationKeySpecifier)
    fields?: ConversationFieldPolicy
  }
  ConversationListResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConversationListResponseKeySpecifier
      | (() => undefined | ConversationListResponseKeySpecifier)
    fields?: ConversationListResponseFieldPolicy
  }
  CreateRetailBountyError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateRetailBountyErrorKeySpecifier
      | (() => undefined | CreateRetailBountyErrorKeySpecifier)
    fields?: CreateRetailBountyErrorFieldPolicy
  }
  DeleteUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteUserResponseKeySpecifier
      | (() => undefined | DeleteUserResponseKeySpecifier)
    fields?: DeleteUserResponseFieldPolicy
  }
  EmailVerificationRequestResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | EmailVerificationRequestResponseKeySpecifier
      | (() => undefined | EmailVerificationRequestResponseKeySpecifier)
    fields?: EmailVerificationRequestResponseFieldPolicy
  }
  GiftOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferKeySpecifier
      | (() => undefined | GiftOfferKeySpecifier)
    fields?: GiftOfferFieldPolicy
  }
  GiftOfferBounty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferBountyKeySpecifier
      | (() => undefined | GiftOfferBountyKeySpecifier)
    fields?: GiftOfferBountyFieldPolicy
  }
  GiftOfferBountyDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferBountyDetailsKeySpecifier
      | (() => undefined | GiftOfferBountyDetailsKeySpecifier)
    fields?: GiftOfferBountyDetailsFieldPolicy
  }
  GiftOfferError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferErrorKeySpecifier
      | (() => undefined | GiftOfferErrorKeySpecifier)
    fields?: GiftOfferErrorFieldPolicy
  }
  GiftOfferProduct?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferProductKeySpecifier
      | (() => undefined | GiftOfferProductKeySpecifier)
    fields?: GiftOfferProductFieldPolicy
  }
  GiftOfferProductDetailOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferProductDetailOptionKeySpecifier
      | (() => undefined | GiftOfferProductDetailOptionKeySpecifier)
    fields?: GiftOfferProductDetailOptionFieldPolicy
  }
  GiftOfferProductDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferProductDetailsKeySpecifier
      | (() => undefined | GiftOfferProductDetailsKeySpecifier)
    fields?: GiftOfferProductDetailsFieldPolicy
  }
  GiftOfferProductOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferProductOptionKeySpecifier
      | (() => undefined | GiftOfferProductOptionKeySpecifier)
    fields?: GiftOfferProductOptionFieldPolicy
  }
  GiftOfferProductVariant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GiftOfferProductVariantKeySpecifier
      | (() => undefined | GiftOfferProductVariantKeySpecifier)
    fields?: GiftOfferProductVariantFieldPolicy
  }
  InstagramContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramContentKeySpecifier
      | (() => undefined | InstagramContentKeySpecifier)
    fields?: InstagramContentFieldPolicy
  }
  InstagramContentPoll?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramContentPollKeySpecifier
      | (() => undefined | InstagramContentPollKeySpecifier)
    fields?: InstagramContentPollFieldPolicy
  }
  InstagramProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramProfileKeySpecifier
      | (() => undefined | InstagramProfileKeySpecifier)
    fields?: InstagramProfileFieldPolicy
  }
  InstagramProfileCheckResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramProfileCheckResultKeySpecifier
      | (() => undefined | InstagramProfileCheckResultKeySpecifier)
    fields?: InstagramProfileCheckResultFieldPolicy
  }
  InstagramProfileCheckResultData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramProfileCheckResultDataKeySpecifier
      | (() => undefined | InstagramProfileCheckResultDataKeySpecifier)
    fields?: InstagramProfileCheckResultDataFieldPolicy
  }
  InstagramProfileConfirmResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InstagramProfileConfirmResultKeySpecifier
      | (() => undefined | InstagramProfileConfirmResultKeySpecifier)
    fields?: InstagramProfileConfirmResultFieldPolicy
  }
  JoinCommunityResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JoinCommunityResultKeySpecifier
      | (() => undefined | JoinCommunityResultKeySpecifier)
    fields?: JoinCommunityResultFieldPolicy
  }
  Message?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageKeySpecifier
      | (() => undefined | MessageKeySpecifier)
    fields?: MessageFieldPolicy
  }
  MessageSender?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageSenderKeySpecifier
      | (() => undefined | MessageSenderKeySpecifier)
    fields?: MessageSenderFieldPolicy
  }
  MinimumPayoutInformation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MinimumPayoutInformationKeySpecifier
      | (() => undefined | MinimumPayoutInformationKeySpecifier)
    fields?: MinimumPayoutInformationFieldPolicy
  }
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier)
    fields?: MutationFieldPolicy
  }
  PayoutForViewsByBountyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PayoutForViewsByBountyResponseKeySpecifier
      | (() => undefined | PayoutForViewsByBountyResponseKeySpecifier)
    fields?: PayoutForViewsByBountyResponseFieldPolicy
  }
  PayoutForViewsByStoreResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PayoutForViewsByStoreResponseKeySpecifier
      | (() => undefined | PayoutForViewsByStoreResponseKeySpecifier)
    fields?: PayoutForViewsByStoreResponseFieldPolicy
  }
  ProductInstruction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductInstructionKeySpecifier
      | (() => undefined | ProductInstructionKeySpecifier)
    fields?: ProductInstructionFieldPolicy
  }
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier)
    fields?: QueryFieldPolicy
  }
  RedeemGiftOfferProductResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RedeemGiftOfferProductResultKeySpecifier
      | (() => undefined | RedeemGiftOfferProductResultKeySpecifier)
    fields?: RedeemGiftOfferProductResultFieldPolicy
  }
  RetailBrief?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RetailBriefKeySpecifier
      | (() => undefined | RetailBriefKeySpecifier)
    fields?: RetailBriefFieldPolicy
  }
  ShopifyStore?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyStoreKeySpecifier
      | (() => undefined | ShopifyStoreKeySpecifier)
    fields?: ShopifyStoreFieldPolicy
  }
  ShopifyStoreDeal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyStoreDealKeySpecifier
      | (() => undefined | ShopifyStoreDealKeySpecifier)
    fields?: ShopifyStoreDealFieldPolicy
  }
  ShopifyStoreOrder?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyStoreOrderKeySpecifier
      | (() => undefined | ShopifyStoreOrderKeySpecifier)
    fields?: ShopifyStoreOrderFieldPolicy
  }
  ShopifyStorePreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyStorePreviewKeySpecifier
      | (() => undefined | ShopifyStorePreviewKeySpecifier)
    fields?: ShopifyStorePreviewFieldPolicy
  }
  ShopifyStoreProduct?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ShopifyStoreProductKeySpecifier
      | (() => undefined | ShopifyStoreProductKeySpecifier)
    fields?: ShopifyStoreProductFieldPolicy
  }
  SparkAdCodeRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SparkAdCodeRequestKeySpecifier
      | (() => undefined | SparkAdCodeRequestKeySpecifier)
    fields?: SparkAdCodeRequestFieldPolicy
  }
  SparkAdCodeRequestLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SparkAdCodeRequestLogKeySpecifier
      | (() => undefined | SparkAdCodeRequestLogKeySpecifier)
    fields?: SparkAdCodeRequestLogFieldPolicy
  }
  StoreCPMStartingPayout?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StoreCPMStartingPayoutKeySpecifier
      | (() => undefined | StoreCPMStartingPayoutKeySpecifier)
    fields?: StoreCPMStartingPayoutFieldPolicy
  }
  StripeAccountInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StripeAccountInfoKeySpecifier
      | (() => undefined | StripeAccountInfoKeySpecifier)
    fields?: StripeAccountInfoFieldPolicy
  }
  StripeAccountLinkingResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StripeAccountLinkingResultKeySpecifier
      | (() => undefined | StripeAccountLinkingResultKeySpecifier)
    fields?: StripeAccountLinkingResultFieldPolicy
  }
  StripeAccountLoginLinkResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StripeAccountLoginLinkResultKeySpecifier
      | (() => undefined | StripeAccountLoginLinkResultKeySpecifier)
    fields?: StripeAccountLoginLinkResultFieldPolicy
  }
  TikTokProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TikTokProfileKeySpecifier
      | (() => undefined | TikTokProfileKeySpecifier)
    fields?: TikTokProfileFieldPolicy
  }
  TikTokUserInfoDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TikTokUserInfoDetailsKeySpecifier
      | (() => undefined | TikTokUserInfoDetailsKeySpecifier)
    fields?: TikTokUserInfoDetailsFieldPolicy
  }
  TikTokUserVideo_RAW?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TikTokUserVideo_RAWKeySpecifier
      | (() => undefined | TikTokUserVideo_RAWKeySpecifier)
    fields?: TikTokUserVideo_RAWFieldPolicy
  }
  TikTokVideo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TikTokVideoKeySpecifier
      | (() => undefined | TikTokVideoKeySpecifier)
    fields?: TikTokVideoFieldPolicy
  }
  TikTokVideoPoll?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TikTokVideoPollKeySpecifier
      | (() => undefined | TikTokVideoPollKeySpecifier)
    fields?: TikTokVideoPollFieldPolicy
  }
  TopVideo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopVideoKeySpecifier
      | (() => undefined | TopVideoKeySpecifier)
    fields?: TopVideoFieldPolicy
  }
  TopVideoProduct?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopVideoProductKeySpecifier
      | (() => undefined | TopVideoProductKeySpecifier)
    fields?: TopVideoProductFieldPolicy
  }
  TopVideoSocialProfile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopVideoSocialProfileKeySpecifier
      | (() => undefined | TopVideoSocialProfileKeySpecifier)
    fields?: TopVideoSocialProfileFieldPolicy
  }
  TopVideoStore?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TopVideoStoreKeySpecifier
      | (() => undefined | TopVideoStoreKeySpecifier)
    fields?: TopVideoStoreFieldPolicy
  }
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier)
    fields?: UserFieldPolicy
  }
  UserBounty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBountyKeySpecifier
      | (() => undefined | UserBountyKeySpecifier)
    fields?: UserBountyFieldPolicy
  }
  UserBountyExpirationExtension?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBountyExpirationExtensionKeySpecifier
      | (() => undefined | UserBountyExpirationExtensionKeySpecifier)
    fields?: UserBountyExpirationExtensionFieldPolicy
  }
  UserBountyRating?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBountyRatingKeySpecifier
      | (() => undefined | UserBountyRatingKeySpecifier)
    fields?: UserBountyRatingFieldPolicy
  }
  UserBountyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBountyResponseKeySpecifier
      | (() => undefined | UserBountyResponseKeySpecifier)
    fields?: UserBountyResponseFieldPolicy
  }
  UserBounty_PaymentSnapshot?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBounty_PaymentSnapshotKeySpecifier
      | (() => undefined | UserBounty_PaymentSnapshotKeySpecifier)
    fields?: UserBounty_PaymentSnapshotFieldPolicy
  }
  UserBounty_ProductGiftConfig?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBounty_ProductGiftConfigKeySpecifier
      | (() => undefined | UserBounty_ProductGiftConfigKeySpecifier)
    fields?: UserBounty_ProductGiftConfigFieldPolicy
  }
  UserMediaFeedback?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserMediaFeedbackKeySpecifier
      | (() => undefined | UserMediaFeedbackKeySpecifier)
    fields?: UserMediaFeedbackFieldPolicy
  }
  UserNotificationPreferences?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserNotificationPreferencesKeySpecifier
      | (() => undefined | UserNotificationPreferencesKeySpecifier)
    fields?: UserNotificationPreferencesFieldPolicy
  }
  UserNotificationPreferences_MediumGroupConfig?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | UserNotificationPreferences_MediumGroupConfigKeySpecifier
      | (() =>
          | undefined
          | UserNotificationPreferences_MediumGroupConfigKeySpecifier)
    fields?: UserNotificationPreferences_MediumGroupConfigFieldPolicy
  }
  UserPayment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserPaymentKeySpecifier
      | (() => undefined | UserPaymentKeySpecifier)
    fields?: UserPaymentFieldPolicy
  }
  UserPaymentSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserPaymentSummaryKeySpecifier
      | (() => undefined | UserPaymentSummaryKeySpecifier)
    fields?: UserPaymentSummaryFieldPolicy
  }
  UserPayoutRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserPayoutRequestKeySpecifier
      | (() => undefined | UserPayoutRequestKeySpecifier)
    fields?: UserPayoutRequestFieldPolicy
  }
  UserPayoutRequestResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserPayoutRequestResponseKeySpecifier
      | (() => undefined | UserPayoutRequestResponseKeySpecifier)
    fields?: UserPayoutRequestResponseFieldPolicy
  }
  UserProfileInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserProfileInfoKeySpecifier
      | (() => undefined | UserProfileInfoKeySpecifier)
    fields?: UserProfileInfoFieldPolicy
  }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies
